import React from "react";
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Routes, Route } from "react-router-dom";
// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from "./pages/home";
import Shop from "./pages/shop";
import Productdetails from "./pages/product_details";
import Login from "./pages/login";
import Register from "./pages/register";
import Cart from "./pages/cart";
import Checkout from "./pages/checkout";
import Faq from "./pages/faq";
import About from "./pages/about";
import Contact from "./pages/contact";
import Blog from "./pages/blog";
import Wishlist from "./pages/wishlist";
import Blogdetails from "./pages/blog_details";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Dashboard from "../src/pages/dashboard"
import Orders from "./components/dashboard/orders";
import Account from "./components/dashboard/account";
import Address from "./components/dashboard/address";
import ChangePassword from "./components/dashboard/changePassword";
import Privacypolicy from "./components/footer/policies/Privacy_policy.jsx";
import TermsConditions from "./components/footer/policies/Terms_ponditions.jsx";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const queryClient = new QueryClient();

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/shop/:itemId" element={<Shop />} />
          <Route
            exact
            path="/product_details/:title"
            element={<Productdetails />}
          />
          <Route exact path="/cart" element={<Cart />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog_details/:blogId" element={<Blogdetails />} />
          <Route exact path="/wishlist" element={<Wishlist />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/account" element={<Account />} />
          <Route exact path="/address" element={<Address />} />
          <Route exact path="/change_password" element={<ChangePassword />} />
          
          <Route exact path="/Privacypolicy" element={<Privacypolicy />} />
          <Route exact path="/TermsConditions" element={<TermsConditions />} />
        </Routes>
        <Footer />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <ToastContainer />
    </>
  );
}

export default App;
