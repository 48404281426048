import { useQuery } from "react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { getOffer } from "../../utils/getOffer";
import { useDispatch, useSelector } from "react-redux";
import { productByAddCart } from "../../redux/productAction";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { get } from "../../utils/grtIdinlocal";
import { toastSuccess } from "../../utils/notifyCustom"
const options = {
  margin: 30,
  responsiveClass: true,
  autoplayHoverPause:true,
  nav: false,
  dots: false,
  autoplay: true,
  loop: true,
  // navText: ["Prev", "Next"],
  autoplayTimeout: 2500,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 2,
    },
    700: {
      items: 2,
    },
    1000: {
      items: 4,
    },
  },
};

const DealsOfTheDay = () => {
  const dispatch = useDispatch();
  const cartData = useSelector((state) => state.cartData);
  const loadingBar = useRef(null);
  const navigate = useNavigate();
  const fetchDealsofthedayProducts = async () => {
    const formData = new FormData();
    formData.append("q_type", "deal");

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_SELECTED_PRODUCTS}`,
      formData
    );
    return response.data.product;
  };

  const productDealsofthedayQuery = useQuery(
    "dealsofthedayproducts",
    fetchDealsofthedayProducts, {
      refetchOnMount: false,
    refetchOnWindowFocus: false
  }
  );

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (productDealsofthedayQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [productDealsofthedayQuery.isLoading]);

  if (productDealsofthedayQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  console.log("uuuuuddd", productDealsofthedayQuery.data);

  const hendleAddToCatd = async (id) => {
    if (cartData.find((cartItem) => +cartItem.id == id)) {
      navigate('/cart')
    } else {
      const cartIdsArr = get(id);
      console.log(cartIdsArr);
      dispatch(productByAddCart(cartIdsArr));
      toastSuccess("Successfully Add to cart")
    }

  };
  return (
    <div>
      <LoadingBar color="#f11946" ref={loadingBar} />
      <section className="top-dels light-bg-s pt-30">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section__head d-flex justify-content-between mb-30">
                <div className="section__title section__title-2">
                  <h5 className="st-titile">Top Deals Of The Day</h5>
                </div>
                {/* <div className="button-wrap button-wrap-2">
                                    <a href="product.html">See All Product <i className="fa fa-chevron-right"></i></a>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="row row-cols-xxl-5 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-2 row-cols-1">
            <OwlCarousel {...options}>
              {productDealsofthedayQuery.data &&
                productDealsofthedayQuery.data.map((item, index) => (
                  <div key={index} className="col item">
                    <div className="product__item product__item-2 b-radius-2 mb-20">
                      <div className="product__thumb fix">
                        <div className="product-image w-img mb-3">
                          <Link
                            to={`/product_details/${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`}
                              alt="product"
                              style={{
                                // width: "100%",
                                height: "200px",
                                // maxHeight: "200px",
                                objectFit: "contain"
                              }} // Adjust the max height as needed
                            />
                          </Link>
                        </div>
                        <div className="product__offer">
                          <span
                            className="discount"
                            style={{
                              display:
                                getOffer(item.price, item.sell_price) == "0"
                                  ? "none"
                                  : "inline",
                            }}
                          >
                            {getOffer(item.price, item.sell_price)}%
                          </span>
                        </div>
                        <div className="product-action product-action-2">
                          <Link
                            to={`/product_details/${encodeURIComponent(
                              item.title
                            )}`}
                          >
                            <a
                              className="icon-box icon-box-1"
                              data-bs-toggle="modal"
                              data-bs-target="#productModalId"
                            >
                              <i className="fa fa-eye"></i>
                              <i className="fa fa-eye"></i>
                            </a>
                          </Link>
                          <a className="icon-box icon-box-1">
                            <i className="fa fa-heart"></i>
                            <i className="fa fa-heart"></i>
                          </a>
                          {/* <a  className="icon-box icon-box-1">
                                                        <i className="fa fa-layer-group"></i>
                                                        <i className="fa fa-layer-group"></i>
                                                    </a> */}
                        </div>
                      </div>
                      <div className="product__content product__content-3">
                        <Link
                          to={`/product_details/${encodeURIComponent(
                            item.title
                          )}`}
                        >
                          <h6>
                            <a className="a1">{`${item.cat_name}, ${item.item_name}`}</a>
                          </h6>
                          <h6>
                            <a className="a2">{item.name}</a>
                          </h6>
                        </Link>
                        <div className="rating mb-5">
                          <ul>
                            <li>
                              <a href="#">
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i className="fa fa-star"></i>
                              </a>
                            </li>
                          </ul>
                          {/* <span>(01 review)</span> */}
                        </div>
                        <div className="price mb-20">
                          <span>
                            ₹{item.sell_price} - <del>₹{item.price}</del>
                          </span>
                        </div>
                        {/* <div className="progress mb-5">
                          <div
                            className="progress-bar bg-danger"
                            role="progressbar"
                            style={{ width: "20%" }}
                            aria-valuemax="100"
                            aria-valuemin="0"
                            maxLength="100"
                          ></div>
                        </div>
                        <div className="progress-rate">
                          <span>Sold:315/1225</span>
                        </div> */}
                      </div>
                      <div className="product__add-cart text-center">
                        <button
                          onClick={() => hendleAddToCatd(item.id)}
                          type="button"
                          className={
                            `product-modal-sidebar-open-btn d-flex align-items-center justify-content-center w-100  ${cartData.find((cartItem) => +cartItem.id == +item.id)
                              ? "cart-btn-3"
                              : "cart-btn"
                            }`
                          }
                        >
                          {cartData.find(
                            (cartItem) => +cartItem.id == +item.id
                          )
                            ? "view cart"
                            : "Add to Cart"}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DealsOfTheDay;
