import React, { useState, useEffect, useRef } from "react";
import contactbanner from '../viewer_assets/img/banner/page-banner-3.jpg'
import location1 from '../viewer_assets/img/location/location-1.jpg'
import location2 from '../viewer_assets/img/location/location-2.jpg'
import location3 from '../viewer_assets/img/location/location-3.jpg'
import location4 from '../viewer_assets/img/location/location-4.jpg'
import location5 from '../viewer_assets/img/contact/5124556.jpg'
import axios from "axios";
import { useQuery } from "react-query";
import LoadingBar from "react-top-loading-bar";
import { toastSuccess, toastWarn } from "../utils/notifyCustom"
import { Link } from "react-router-dom";
const formContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    padding: '20px',
};

const leftSideStyle = {
    flex: '1',

};

const rightSideStyle = {
    flex: '1',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    maxWidth: '400px',
};

const imgStyle = {
    width: '600px',
    height: '600px',
    borderRadius: '5px',
};

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
};

const labelStyle = {
    margin: '3px 0',
    fontWeight: 'bold',
};

const inputStyle = {
    padding: '7px',
    margin: '5px 0',
    borderRadius: '3px',
    border: '1px solid #ccc',
};

const textareaStyle = {
    padding: '7px',
    margin: '5px 0',
    borderRadius: '3px',
    border: '1px solid #ccc',
};

const buttonStyle = {
    padding: '8px',
    background: '#263c97',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'background 0.3s',
};

const buttondStyle = {
    padding: '8px',
    background: '#808080',
    color: '#fff',
    border: 'none',
    borderRadius: '3px',
    cursor: 'pointer',
    transition: 'background 0.3s',
};

const buttonHoverStyle = {
    background: '#1a2e73',
};

const fetchContact = async ({ name, email, phone, subject, message }) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('phone', phone);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('message', message);

    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_CONTACT_FROM}`,
        formData
    );

    return response.data.message;
};

function Contact() {
    const loadingBar = useRef(null);
    const [name, setname] = useState('')
    const [email, setemail] = useState('')
    const [message, setmessage] = useState('')
    const [phone, setphone] = useState('')
    const [subject, setsubject] = useState('')

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

    }, [])

    const { data, isLoading, error, refetch, isSuccess } = useQuery(
        ['contact', { name, email, phone, subject, message }],
        () => fetchContact({ name, email, phone, subject, message }),
        {
            enabled: false,
        }
    );
    const handleClick = async (e) => {
        e.preventDefault();
        // Trigger a data fetch when the button is clicked
        await refetch();
    };


    const startLoading = () => {
        loadingBar.current.continuousStart();
    };

    const finishLoading = () => {
        loadingBar.current.complete();
    };

    useEffect(() => {
        if (isLoading) {
            startLoading();
        } else {
            finishLoading();
        }
    }, [isLoading]);

    console.log(isSuccess)
    if (isSuccess) {
        toastSuccess(data)
        setname('');
        setemail('');
        setmessage('');
        setphone('');
        setsubject('');
    }

    if (error) {
        toastWarn(error)
    }

    return (
        <div>
            <LoadingBar color="#f11946" ref={loadingBar} />
            {/* <!-- page-banner-area-start --> */}
            <div class="page-banner-area page-banner-height" style={{ backgroundImage: `url(${contactbanner})` }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-banner-content text-center">
                                <h4 class="breadcrumb-title">Contact Us</h4>
                                <div class="breadcrumb-two">
                                    <nav>
                                        <nav class="breadcrumb-trail breadcrumbs">
                                            <ul class="breadcrumb-menu">
                                                <li class="breadcrumb-trail">
                                                    <Link to={"/"}><span>Home</span></Link>
                                                </li>
                                                <li class="trail-item">
                                                    <span>Contact Us</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- page-banner-area-end --> */}

            {/* <!-- location-area-start --> */}
            <div class="location-area pt-70 pb-25">
                <div class="container">
                    <div class="row mb-25">
                        <div class="col-xl-12">
                            <div class="abs-section-title text-center">
                                <span>LOCATION STORE</span>
                                <h4>Where We Are</h4>
                                <p>The perfect way to enjoy brewing tea on low hanging fruit to identify. Duis autem vel eum iriure dolor in hendrerit <br /> in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="location-item mb-30">
                                <div class="location-image w-img mb-20">
                                    <img src={location1} alt="logo" />
                                </div>
                                <h6>26 Rue Pelleport - Paris</h6>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-map-marker-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Find us</span>
                                        <p>Rue Saint-Antoine, Paris, France  </p>
                                    </div>
                                </div>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-phone-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Call us</span>
                                        <p><a href="tel:+8804568">(+100) 123 456 7890</a></p>
                                    </div>
                                </div>
                                <div class="sm-item-loc mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Mail us</span>
                                        <p><a href="mailto:store@company.com">store@company.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="location-item mb-30">
                                <div class="location-image w-img mb-20">
                                    <img src={location2} alt="logo" />
                                </div>
                                <h6>150 Stanley Rd - London</h6>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-map-marker-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Find us</span>
                                        <p>Brick Ln, Spitalfields, London E1, UK</p>
                                    </div>
                                </div>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-phone-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Call us</span>
                                        <p><a href="tel:+8804568">(+100) 123 456 7890</a></p>
                                    </div>
                                </div>
                                <div class="sm-item-loc mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Mail us</span>
                                        <p><a href="mailto:store@company.com">store@company.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="location-item mb-30">
                                <div class="location-image w-img mb-20">
                                    <img src={location3} alt="logo" />
                                </div>
                                <h6>1357 Prospect - New York</h6>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-map-marker-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Find us</span>
                                        <p>Atlantic, Brooklyn, New York, US</p>
                                    </div>
                                </div>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-phone-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Call us</span>
                                        <p><a href="tel:+8804568">(+100) 123 456 7890</a></p>
                                    </div>
                                </div>
                                <div class="sm-item-loc mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Mail us</span>
                                        <p><a href="mailto:store@company.com">store@company.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-lg-3 col-md-6">
                            <div class="location-item mb-30">
                                <div class="location-image w-img mb-20">
                                    <img src={location4} alt="logo" />
                                </div>
                                <h6>86 Georgia Rd - Mardrid</h6>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-map-marker-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Find us</span>
                                        <p>Calle del Correo, 4, Madrid, Spain</p>
                                    </div>
                                </div>
                                <div class="sm-item-loc sm-item-border mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-phone-alt"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Call us</span>
                                        <p><a href="tel:+8804568">(+100) 123 456 7890</a></p>
                                    </div>
                                </div>
                                <div class="sm-item-loc mb-20">
                                    <div class="sml-icon mr-20">
                                        <i class="fa fa-envelope"></i>
                                    </div>
                                    <div class="sm-content">
                                        <span>Mail us</span>
                                        <p><a href="mailto:store@company.com">store@company.com</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- location-area-end --> */}
            <div style={formContainerStyle}>
                <div style={leftSideStyle}>
                    <img
                        src={location5} // Replace with the actual image URL
                        alt="Contact Us"
                        style={imgStyle}
                    />
                </div>
                <div style={rightSideStyle}>
                    <form style={formStyle}>
                        <label htmlFor="name" style={labelStyle}>Name</label>
                        <input onChange={(e) => setname(e.target.value)} type="text" id="name" name="name" style={inputStyle} required />

                        <label htmlFor="email" style={labelStyle}>Email</label>
                        <input onChange={(e) => setemail(e.target.value)} type="email" id="email" name="email" style={inputStyle} required />


                        <label htmlFor="phone" style={labelStyle}>Phone</label>
                        <input onChange={(e) => setphone(e.target.value)} type="number" id="phone" name="phone" style={inputStyle} required />

                        <label htmlFor="subject" style={labelStyle}>Subject</label>
                        <input onChange={(e) => setsubject(e.target.value)} type="text" id="subject" name="subject" style={inputStyle} required />

                        <label htmlFor="message" style={labelStyle}>Message</label>
                        <textarea onChange={(e) => setmessage(e.target.value)} id="message" name="message" rows="4" style={textareaStyle} required></textarea>

                        <button
                            onClick={handleClick}
                            disabled={isLoading || !name || !email || !phone || !subject || !message}
                            style={ isLoading || !name || !email || !phone || !subject || !message ? buttondStyle : buttonStyle}
                            type="submit"
                        >
                            {isLoading ? 'Loading...' : 'Submit'}
                        </button>

                    </form>
                </div>
            </div>
            {/* <!-- cmamps-area-start --> */}
            <div class="cmamps-area">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1899531.5831083965!2d105.806381!3d21.58504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x515f4860ede9e108!2zVHLGsOG7nW5nIMSQ4bqhaSBo4buNYyBDw7RuZyBuZ2jhu4cgVGjDtG5nIHRpbiB2w6AgVHJ1eeG7gW4gdGjDtG5n!5e0!3m2!1sen!2sus!4v1644226635446!5m2!1sen!2sus"></iframe>
            </div>
            {/* <!-- cmamps-area-end --> */}
        </div>
    )
}

export default Contact