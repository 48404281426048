import React, { useEffect } from 'react'
// import './App.css';



import quickview1 from '../viewer_assets/img/quick-view/quick-view-1.jpg'
import quickview2 from '../viewer_assets/img/quick-view/quick-view-2.jpg'
import quickview3 from '../viewer_assets/img/quick-view/quick-view-3.jpg'
import quickview4 from '../viewer_assets/img/quick-view/quick-view-4.jpg'
import quicknav1 from '../viewer_assets/img/quick-view/quick-nav-1.jpg'
import quicknav2 from '../viewer_assets/img/quick-view/quick-nav-2.jpg'
import quicknav3 from '../viewer_assets/img/quick-view/quick-nav-3.jpg'
import quicknav4 from '../viewer_assets/img/quick-view/quick-nav-4.jpg'
import Brand_slider from '../components/slider/brand_slider'


import Recentproducts from '../components/recentproducts/recentProducts'

import Featured_start from "../components/Featured_start/featured_start"

import DealsOfTheDay from '../components/deals _Of_The_Day/dealsOfTheDay'
import HomeBloglist from '../components/blog/homeBloglist'
import Banner from '../components/slider/banner'
import Banner1 from '../components/slider/banner1'
import HotOnSale from '../components/hotOnSale/hotOnSale'

const Home = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

    }, [])
    return (
        <div>
            <main>
                <Banner />
                {/* <!-- slider-area-start --> */}

                {/* <!-- slider-area-end --> */}

                {/* <!-- top-dels-start --> */}
                <DealsOfTheDay />
                {/* <!-- top-dels-end --> */}

                {/* <!-- featured-start --> */}
                <Featured_start />
                {/* <!-- featured-end --> */}

                {/* <!-- banner__area-start --> */}
                <Banner1 />
                {/* <!-- banner__area-end --> */}

                {/* <!-- trending-product-area-start --> */}
                <Recentproducts />
                {/* <!-- trending-product-area-end --> */}

                {/* <!-- doubale-product-area-start --> */}
                <HotOnSale />
                {/* <!-- doubale-product-area-end --> */}

                {/* <!-- moveing-text-area-start --> */}
                <section className="moveing-text-area">
                    <div className="container">
                        <div className="ovic-running">
                            <div className="wrap">
                                <div className="inner">
                                    <p className="item">Free UK Delivery - Return Over $100.00 ( Excluding Homeware )   |   Free UK Collect From Store</p>
                                    <p className="item">Design Week / 15% Off the website / Code: AYOSALE-2020</p>
                                    <p className="item">Always iconic. Now organic. Introducing the $20 Organic Tee.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- moveing-text-area-end --> */}

                {/* <!-- blog-area-start --> */}
                <HomeBloglist />
                {/* <!-- blog-area-end --> */}

                <Brand_slider />

                {/* <!-- shop modal start --> */}
                <div className="modal fade" id="productModalId" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered product__modal" role="document">
                        <div className="modal-content">
                            <div className="product__modal-wrapper p-relative">
                                <div className="product__modal-close p-absolute">
                                    <button data-bs-dismiss="modal"><i className="fa fa-times"></i></button>
                                </div>
                                <div className="product__modal-inner">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="product__modal-box">
                                                <div className="tab-content" id="modalTabContent">
                                                    <div className="tab-pane fade show active" id="nav1" role="tabpanel" aria-labelledby="nav1-tab">
                                                        <div className="product__modal-img w-img">
                                                            <img src={quickview1} alt="logo" />
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav2" role="tabpanel" aria-labelledby="nav2-tab">
                                                        <div className="product__modal-img w-img">
                                                            <img src={quickview2} alt="logo" />
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav3" role="tabpanel" aria-labelledby="nav3-tab">
                                                        <div className="product__modal-img w-img">
                                                            <img src={quickview3} alt="logo" />
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="nav4" role="tabpanel" aria-labelledby="nav4-tab">
                                                        <div className="product__modal-img w-img">
                                                            <img src={quickview4} alt="logo" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul className="nav nav-tabs" id="modalTab" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link active" id="nav1-tab" data-bs-toggle="tab" data-bs-target="#nav1" type="button" role="tab" aria-controls="nav1" aria-selected="true">
                                                            <img src={quicknav1} alt="logo" />
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="nav2-tab" data-bs-toggle="tab" data-bs-target="#nav2" type="button" role="tab" aria-controls="nav2" aria-selected="fase">
                                                            <img src={quicknav2} alt="logo" />
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="nav3-tab" data-bs-toggle="tab" data-bs-target="#nav3" type="button" role="tab" aria-controls="nav3" aria-selected="fase">
                                                            <img src={quicknav3} alt="logo" />
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button className="nav-link" id="nav4-tab" data-bs-toggle="tab" data-bs-target="#nav4" type="button" role="tab" aria-controls="nav4" aria-selected="fase">
                                                            <img src={quicknav4} alt="logo" />
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                            <div className="product__modal-content">
                                                <h4><a href="product-details.html">Samsung C49J89: £875, Debenhams Plus</a></h4>
                                                <div className="product__review d-sm-flex">
                                                    <div className="rating rating__shop mb-10 mr-30">
                                                        <ul>
                                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                        </ul>
                                                    </div>
                                                    <div className="product__add-review mb-15">
                                                        <span>01 review</span>
                                                    </div>
                                                </div>
                                                <div className="product__price">
                                                    <span>$109.00 – $307.00</span>
                                                </div>
                                                <div className="product__modal-des mt-20 mb-15">
                                                    <ul>
                                                        <li><a href="#"><i className="fas fa-circle"></i> Bass and Stereo Sound.</a></li>
                                                        <li><a href="#"><i className="fas fa-circle"></i> Display with 3088 x 1440 pixels resolution.</a></li>
                                                        <li><a href="#"><i className="fas fa-circle"></i> Memory, Storage & SIM: 12GB RAM, 256GB.</a></li>
                                                        <li><a href="#"><i className="fas fa-circle"></i> Androi v10.0 Operating system.</a></li>
                                                    </ul>
                                                </div>
                                                <div className="product__stock mb-20">
                                                    <span className="mr-10">Availability :</span>
                                                    <span>1795 in stock</span>
                                                </div>
                                                <div className="product__modal-form">
                                                    <form action="#">
                                                        <div className="pro-quan-area d-lg-flex align-items-center">
                                                            <div className="product-quantity mr-20 mb-25">
                                                                <div className="cart-plus-minus p-relative"><input type="text" string="1" /></div>
                                                            </div>
                                                            <div className="pro-cart-btn mb-25">
                                                                <button className="cart-btn" type="submit">Add to cart</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                <div className="product__stock mb-30">
                                                    <ul>
                                                        <li><a href="#">
                                                            <span className="sku mr-10">SKU:</span>
                                                            <span>Samsung C49J89: £875, Debenhams Plus</span></a>
                                                        </li>
                                                        <li><a href="#">
                                                            <span className="cat mr-10">Categories:</span>
                                                            <span>iPhone, Tablets</span></a>
                                                        </li>
                                                        <li><a href="#">
                                                            <span className="tag mr-10">Tags:</span>
                                                            <span>Smartphone, Tablets</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- shop modal end --> */}
            </main>
        </div>
    )
}

export default Home