import React from 'react'
import { Link } from 'react-router-dom'

// import '../../viewer_assets/js/vendor/jquery.js'
// import '../../viewer_assets/js/vendor/waypoints.js'
// import '../../viewer_assets/js/bootstrap-bundle.js'
// import '../../viewer_assets/js/meanmenu.js'
// import '../../viewer_assets/js/swiper-bundle.js'
// import '../../viewer_assets/js/owl-carousel.js'
// import '../../viewer_assets/js/tweenmax.js'
// import '../../viewer_assets/js/magnific-popup.js'
// import '../../viewer_assets/js/parallax.js'
// import '../../viewer_assets/js/backtotop.js'
// import '../../viewer_assets/js/nice-select.js'
// import '../../viewer_assets/js/countdown.min.js'
// import '../../viewer_assets/js/wow.js'
// import '../../viewer_assets/js/isotope-pkgd.js'
// import '../../viewer_assets/js/imagesloaded-pkgd.js'
// import '../../viewer_assets/js/ajax-form.js'
// import '../../viewer_assets/js/main.js'

function footer() {
  return (
    <div>
      <>
        {/* cta-area-start */}
        {/* <section className="cta-area d-ldark-bg pt-55 pb-10">
    <div className="container">
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="cta-item cta-item-d mb-30">
            <h5 className="cta-title">Follow Us</h5>
            <p>
              We make consolidating, marketing and tracking your social media
              website easy.
            </p>
            <div className="cta-social">
              <div className="social-icon">
                <a href="#" className="facebook">
                  <i className="fab fa-facebook-f" />
                </a>
                <a href="#" className="twitter">
                  <i className="fab fa-twitter" />
                </a>
                <a href="#" className="youtube">
                  <i className="fab fa-youtube" />
                </a>
                <a href="#" className="linkedin">
                  <i className="fab fa-linkedin-in" />
                </a>
                <a href="#" className="rss">
                  <i className="fas fa-rss" />
                </a>
                <a href="#" className="dribbble">
                  <i className="fab fa-dribbble" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="cta-item mb-30">
            <h5 className="cta-title">Sign Up To Newsletter</h5>
            <p>
              Join 60.000+ subscribers and get a new discount coupon on every
              Saturday.
            </p>
            <div className="subscribe__form">
              <form action="#">
                <input type="email" placeholder="Enter your email here..." />
                <button>subscribe</button>
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="cta-item mb-30">
            <h5 className="cta-title">Download App</h5>
            <p>
              DukaMarket App is now available on App Store &amp; Google Play.
              Get it now.
            </p>
            <div className="cta-apps">
              <div className="apps-store">
                <a href="#">
                  <img src="viewer_assets/img/brand/app_ios.png" alt="" />
                </a>
                <a href="#">
                  <img src="viewer_assets/img/brand/app_android.png" alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
        {/* cta-area-end */}
        {/* footer-start */}
        <footer>
          <div className="fotter-area d-ddark-bg">
            <div className="footer__top pt-60 pb-10">
              <div className="container 0">
                <div className="row">
                  <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-8">
                    <div className="footer__widget">
                      <div className="footer__widget-title mb-20">
                        <h4>About The Store</h4>
                      </div>
                      <div className="footer__widget-content">
                        <p className="footer-text mb-25">
                          Our mission statement is to provide the absolute best
                          customer experience available in the Electronic industry
                          without exception.
                        </p>
                        <div className="footer__hotline d-flex align-items-center mb-10">
                          <div className="icon icon-2 mr-15">
                            <i className="fa fa-headset" />
                          </div>
                          <div className="text text-2">
                            <h4>Got Question? Call us 24/7!</h4>
                            <span>
                              <a href="tel:100-123-456-7890">(+100) 123 456 7890</a>
                            </span>
                          </div>
                        </div>
                        <div className="footer__info">
                          <ul>
                            <li>
                              <span>
                                Add:{" "}
                                <a
                                  target="_blank"
                                  href="https://goo.gl/maps/c82DDZ8ALvL878Bv8"
                                >
                                  Walls Street 68, Mahattan, New York, USA
                                </a>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget footer-col-2">
                      <div className="footer__widget-title">
                        <h4>My Account</h4>
                      </div>
                      <div className="footer__widget-content">
                        <div className="footer__link footer__link-2">
                          <ul>
                            <li>
                              <Link to={"/dashboard"}>My Account</Link>
                            </li>
                            <li>
                              <Link to={"/checkout"}>Checkout</Link>
                            </li>
                            <li>
                              <Link to={"/cart"}>Shopping Cart</Link>
                            </li>
                            <li>
                              <Link to={"/wishlist"}>Wishlist</Link>
                            </li>
                            <li>
                              <Link to={"/TermsConditions"}>Terms &amp; Conditions</Link>
                            </li>
                            {/* <li>
                              <a href="faq.php">Redeem Voucher</a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div className="footer__widget footer-col-2">
                <div className="footer__widget-title">
                  <h4>Customer Care</h4>
                </div>
                <div className="footer__widget-content">
                  <div className="footer__link footer__link-2">
                    <ul>
                      <li>
                        <a href="faq.php">New Customers</a>
                      </li>
                      <li>
                        <a href="faq.php">How to use Account</a>
                      </li>
                      <li>
                        <a href="faq.php">Placing an Order</a>
                      </li>
                      <li>
                        <a href="faq.php">Payment Methods</a>
                      </li>
                      <li>
                        <a href="faq.php">Delivery &amp; Dispatch</a>
                      </li>
                      <li>
                        <a href="faq.php">Problems with Order</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
                  <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6">
                    <div className="footer__widget footer-col-2">
                      <div className="footer__widget-title">
                        <h4>Customer Service</h4>
                      </div>
                      <div className="footer__widget-content">
                        <div className="footer__link footer__link-2">
                          <ul>
                            <li>
                              <Link to={"/faq"}>Help Center</Link>
                            </li>
                            <li>
                              <Link to={"/contact"}>Contact Us</Link>
                            </li>
                            <li>
                              <Link to={"/about"}>About</Link>
                            </li>
                            <li>
                              <Link to={"/blog"}>Blog</Link>
                            </li>
                            <li>
                              <Link to={"/Privacypolicy"} href="faq.php">Privacy &amp; Policies</Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-6">
                    <div className="footer__widget">
                      <div className="footer__widget-title mb-20">
                        <h4>Follow Us</h4>
                      </div>
                      <p>
                        We make consolidating, marketing and tracking your social media
                        website easy.
                      </p>
                      <div className="cta-social">
                        <div className="social-icon">
                          <a href="#" className="facebook">
                            <i className="fab fa-facebook-f" />
                          </a>
                          <a href="#" className="twitter">
                            <i className="fab fa-twitter" />
                          </a>
                          <a href="#" className="youtube">
                            <i className="fab fa-youtube" />
                          </a>
                          <a href="#" className="linkedin">
                            <i className="fab fa-linkedin-in" />
                          </a>
                          {/* <a href="#" className="rss">
                            <i className="fas fa-rss" />
                          </a> */}
                          {/* <a href="#" className="dribbble">
                            <i className="fab fa-dribbble" />
                          </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__bottom-2">
              <div className="container 0">
                <div className="footer__bottom-content footer__bottom-content-2 pt-3 pb-3">
                  <div className="row">
                    <div className="col-xl-12">
                      {/* <div className="footer__links footer__links-d text-center mb-25">
                        <p>
                          <a href="about.php">About Us</a>
                          <a href="contact.php">Delivery &amp; Return</a>
                          <a href="faq.php">Privacy Policy</a>
                          <a href="faq.php">Help</a>
                          <a href="faq.php">Order Tracking</a>
                          <a href="contact.php">Contact Us</a>
                          <a href="faq.php">FAQs </a>
                        </p>
                      </div> */}
                      {/*<div className="payment-image text-center mb-25">
                        <a href="#">
                          <img src="assets/img/payment/payment.png" alt="" />
                        </a>
                      </div>*/}
                      <div className="copy-right-area copy-right-area-2 text-center">
                        <p className="mb-0">
                          Copyright © <span>MK-Ecommerce</span> All Rights Reserved
                          {/* Powered by{" "} */}
                          <a href="#">
                            {/* <span className="main-color">Theme_Pure.</span> */}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        {/* footer-end */}
      </>

    </div>
  )
}

export default footer