import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { FaFileInvoice } from "react-icons/fa";

import "../../css/moadl.css"

const Orders = () => {
  const loadingBar = useRef(null);
const [invoice, setinvoice] = useState()
  const fetchorderlist = async () => {
    const formData = new FormData();
    formData.append("user_id", "15");

    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_MY_ORDER_LIST}`,
      formData
    );
    return response.data;
  };

  const orderlistQuery = useQuery("fetchorderlist", fetchorderlist);

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (orderlistQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [orderlistQuery.isLoading]);

  if (orderlistQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  console.log("uuuuudddkkkkkkiiiii", orderlistQuery.data);

  const handelInvoice = async (id) => {
    console.log(id, "invoicesssss");
    try {
      const formData = new FormData();
      formData.append("user_id", "15");
      formData.append("order_id", id);
      await axios(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_MY_ORDER_INVOICE}`,
        {
          method: "POST",
          data: formData,
        }
      ).then((res) => {
        setinvoice(res.data.html_data)
        console.log(res.data);
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <main>
      <LoadingBar color="#f11946" ref={loadingBar} />
      {/* cart-area-start */}
      <section className="cart-area py-lg-5 py-md-5 py-3">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12">
              <h5 className="mb-3">Order History</h5>
              <div className="order_grid">
                {orderlistQuery.data &&
                  orderlistQuery.data.map((item, index) => (
                    <a className="order_box">
                      <div className="">
                        <h6 className="mb-1">
                          Order ID:{item.order_id}
                          <small >
                            ({item.qty} Products){" "}                      
                          </small>
                        </h6>
                        <p className="mb-0">
                          Expected Delivery: {item.order_date}
                        </p>
                        <div class="wrapper" onClick={() => handelInvoice(item.order_id)}>
                              <a href="#demo-modal">invoice</a>
                            </div>
                      </div>
                      <div>
                        <h5 className="mb-0">₹{item.sell_price}</h5>
                      </div>
                    </a>
                  ))}
              </div>
              <div id="demo-modal" class="modal">
                <div class="modal__content">
                  <p className="mt-25 mb-50" dangerouslySetInnerHTML={{ __html: invoice }}></p>

                  <a href="#" class="modal__close">&times;</a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>
      {/* cart-area-end */}
    </main>
  );
};

export default Orders;
