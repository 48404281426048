import React, { useState, useEffect, useRef } from "react";
import slider1 from '../../viewer_assets/img/slider/03-slide-1.jpg'
import slider2 from '../../viewer_assets/img/slider/03-slide-2.jpg'
import slider3 from '../../viewer_assets/img/slider/03-slide-3.jpg'
import '../../css/animate.css'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import axios from "axios";
import { useQuery } from "react-query";

const options = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  loop: true,
  // navText: ["Prev", "Next"],
  autoplayTimeout: 2500,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 1,
    },
    400: {
      items: 1,
    },
    600: {
      items: 1,
    },
    700: {
      items: 1,
    },
    1000: {
      items: 1,

    }
  },
};
const fetchBanner = async () => {

  const formData = new FormData();
  formData.append("section", "banner");
  formData.append("holder", "1");
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BANNER}`,
    formData
  );
  return response.data;
};

function Single_slider() {


  const bannerQuery = useQuery(["banner1",], () =>
    fetchBanner(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  }
  );

  useEffect(() => {
    // Fetch data when component mounts and when the query key changes
    bannerQuery.refetch();
  }, [bannerQuery.data]);

  // useEffect(() => {
  //   // This block of code will be executed when the component mounts
  //   bannerQuery.refetch(); // Assuming you want to refetch data when the component mounts
  // }, [bannerQuery]); 

  console.log(bannerQuery.data, "====================>>>>>>>>>>>banner")

  return (
    <div>
      <div className="swiper-container slider__active pb-30">
        <div className="slider-wrapper swiper-wrapper">
          <OwlCarousel className='slider-items owl-carousel' {...options}>
            {bannerQuery.data &&
              bannerQuery.data.map((item, index) => (
                <div className='item' key={index}>
                  <div className="single-slider swiper-slide b-radius-2 slider-height-3 d-flex align-items-center">
                    <img
                      src={`https://www.webinatech.in/portfolio/mk_ecom/uploads/advertise/${item.img}`}
                      alt="logo"
                      style={{ position: 'relative', borderRadius: '15px' }}
                      onError={(e) => {
                        console.error(`Error loading image for item ${index}:`, e);
                      }}
                    />
                    <div className="slider-content slider-content-2" style={{ position: 'absolute', zIndex: '10', top: '20%' }}>
                      <div className="slider-top-btn mb-20" data-animation="fadeInLeft" data-delay="1.5s" style={{ animationDelay: '1.5s' }}>
                        {item.heading && item.heading !== "" && (
                          <a className="st-btn b-radius">{item.heading}</a>
                        )}
                      </div>
                      {/* <h2 data-animation="fadeInLeft" data-delay="1.7s" className="pt-15 slider-title pb-5">BIG SALE <br /> TOP HEADPHONE</h2> */}
                      {item.description && item.description !== "" && (
                        <p className="pr-20 slider_text" data-animation="fadeInLeft" data-delay="1.9s">{item.description}</p>
                      )}
                      {item.btn_name && item.btn_name !== "" && (
                        <div className="slider-bottom-btn mt-65">
                          <a data-animation="fadeInUp" data-delay="1.15s" href={`${item.btn_link}`} className="st-btn-border b-radius-2">{item.btn_name}</a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

              ))}
          </OwlCarousel>
          <div className="main-slider-paginations"></div>
        </div>
      </div>
    </div>
  )
}

export default Single_slider