import {
  Product_List,
  Add_To_Cart,
  Remove_To_Cart,
  Product_List_With_filter,
  Emti_Cart
} from "./constant";

export const productByCategory = (catid, subid) => {
  console.log("catid", catid, "subid", subid);
  return {
    type: Product_List,
    catid,
    subid,
  };
};

export const productByFilter = (itemId, filterArr) => {
  console.log("catid", itemId, "subid", filterArr);
  return {
    type: Product_List_With_filter,
    itemId,
    filterArr,
  };
};

export const productByAddCart = (productid) => {
  console.log("productid cart", productid);
  return {
    type: Add_To_Cart,
    productid,
  };
};

export const productByRemoveCart = (productid) => {
  console.log("productid RemoveCart", productid);
  return {
    type: Remove_To_Cart,
    productid,
  };
};

export const productByEmtiCart = () => {
  console.log("productid RemoveCart");
  return {
    type: Emti_Cart,
  };
};
