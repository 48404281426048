import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
const HomeBloglist = () => {

    const loadingBar = useRef(null);

    const fetchbloglist = async () => {
        const formData = new FormData();
        formData.append("user_id", "1");

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BLOG_LIST}`,
            formData
        );
        return response.data;
    };

    const bloglistQuery = useQuery(
        "fetchbloglist",
        fetchbloglist
        , {
            refetchOnMount: false,
            refetchOnWindowFocus: false
        });

    const startLoading = () => {
        loadingBar.current.continuousStart();
    };

    const finishLoading = () => {
        loadingBar.current.complete();
    };

    useEffect(() => {
        if (bloglistQuery.isLoading) {
            startLoading();
        } else {
            finishLoading();
        }
    }, [bloglistQuery.isLoading]);

    if (bloglistQuery.isLoading) {
        return (
            <div>
                <LoadingBar color="#f11946" ref={loadingBar} />
                {/* <h2>Loading...</h2> */}
            </div>
        );
    }
    console.log("uuuuudddkkkkkk", bloglistQuery.data);

    return (
        <div className="blog-area pt-55 pb-75">
            <LoadingBar color="#f11946" ref={loadingBar} />
            <div className="container 0">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section__head d-flex justify-content-between mb-30">
                            <div className="section__title section__title-2">
                                <h5 className="st-titile">From The Blog</h5>
                            </div>
                            <div className="button-wrap button-wrap-2">
                                <Link to={"/blog"}>See All Product <i className="fa fa-chevron-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {bloglistQuery.data &&
                        bloglistQuery.data.map((item, index) => (
                            <div key={index} className="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                                <div className="single-smblog mb-30">
                                    <div className="smblog-thum">
                                        <div className="blog-image blog-image-2 w-img">
                                            <Link to={`/blog_details/${item.id}`} ><img src={`${process.env.REACT_APP_API_BLOG_IMG}/${item.blog_img}`} alt="logo" /></Link>
                                        </div>
                                        <div className="blog-tag">
                                            <a >{item.category_name}</a>
                                        </div>
                                    </div>
                                    <div className="smblog-content smblog-content-2">
                                        <h6><a >{item.blog_title}</a></h6>
                                        {/* <span className="author mb-10">Category <a >{item.category_name}</a></span> */}
                                        <div className="smblog-foot pt-15">
                                            <div className="post-readmore">
                                                <Link to={`/blog_details/${item.id}`} > Read More <span className="icon"></span></Link>
                                            </div>
                                            <div className="post-date">
                                                <a >{item.publish_date}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    {/* <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                <div className="single-smblog mb-30">
                    <div className="smblog-thum">
                        <div className="blog-image blog-image-2 w-img">
                            <a href="blog-details.html"><img src={blogimg2} alt="logo" /></a>
                        </div>
                        <div className="blog-tag">
                            <a href="blog.html">New</a>
                        </div>
                    </div>
                    <div className="smblog-content smblog-content-2">
                        <h6><a href="blog-details.html">Top 5 Best Digital Cameras for 2021 You Should Buy</a></h6>
                        <span className="author mb-10">posted by <a href="#">Angelia</a></span>
                        <div className="smblog-foot pt-15">
                            <div className="post-readmore">
                                <a href="blog-details.html">Read More <span className="icon"></span></a>
                            </div>
                            <div className="post-date">
                                <a href="blog-details.html">Jan 24, 2022</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                <div className="single-smblog mb-30">
                    <div className="smblog-thum">
                        <div className="blog-image blog-image-2 w-img">
                            <a href="blog-details.html"><img src={blogimg3} alt="logo" /></a>
                        </div>
                        <div className="blog-tag">
                            <a href="blog.html">Update</a>
                        </div>
                    </div>
                    <div className="smblog-content smblog-content-2">
                        <h6><a href="blog-details.html">Capture the moment with 4 cameras on Oppo A92</a></h6>
                        <span className="author mb-10">posted by <a href="#">Iqbal</a></span>
                        <div className="smblog-foot pt-15">
                            <div className="post-readmore">
                                <a href="blog-details.html"> Read More <span className="icon"></span></a>
                            </div>
                            <div className="post-date">
                                <a href="blog-details.html">Jan 24, 2022</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4">
                <div className="single-smblog mb-30">
                    <div className="smblog-thum">
                        <div className="blog-image blog-image-2 w-img">
                            <a href="blog-details.html"><img src={blogimg4} alt="logo" /></a>
                        </div>
                        <div className="blog-tag">
                            <a href="blog.html">Offer</a>
                        </div>
                    </div>
                    <div className="smblog-content smblog-content-2">
                        <h6><a href="blog-details.html">Use Headphones Properly Not To Damage Your Hearing</a></h6>
                        <span className="author mb-10">posted by <a href="#">Jenny</a></span>
                        <div className="smblog-foot pt-15">
                            <div className="post-readmore">
                                <a href="blog-details.html"> Read More <span className="icon"></span></a>
                            </div>
                            <div className="post-date">
                                <a href="blog-details.html">Jan 24, 2022</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                </div>
            </div>
        </div>
    )
}

export default HomeBloglist