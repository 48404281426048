// import '../../css/font-awesome-pro.css'
// import {MdOutlineRouter} from 'react-icons/md'
// import { BiWebcam } from "react-icons/bi";
// import { IoBedOutline } from "react-icons/io5";
// import { CiSpeaker } from "react-icons/ci";
// import home from '../../pages/home'
// import cart from '../../pages/cart'
import React, { useEffect, useState, useRef } from "react";
import "../../css/style.css";
import "../../css/bootstrap.css";
import "../../css/meanmenu.css";
import "../../css/animate.css";
import "../../css/owl-carousel.css";
import "../../css/swiper-bundle.css";
import "../../css/backtotop.css";
import "../../css/magnific-popup.css";
import "../../css/nice-select.css";
import "../../css/flaticon.css";
import "../../css/default.css";
import "../../css/style.css";
import logo from "../../viewer_assets/img/logo/logo.png";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { productByCategory } from "../../redux/productAction";
import { useQuery } from "react-query";
import Dropdown from "react-bootstrap/Dropdown";
import "../../css/style.css";
import { useSelector } from "react-redux";
import { productByRemoveCart } from "../../redux/productAction";
import { remove } from "../../utils/grtIdinlocal";
import LoadingBar from "react-top-loading-bar";
import { toastSuccess } from "../../utils/notifyCustom";
import { quantityAdd } from "../../utils/quantity";
// import Spinner from "react-bootstrap/Spinner";
// import Sidenav from "../../pages/sidenav";
const header_style = {
  display: "flex",
  alignItems: "center",
  // ... add more styles as needed
};

const fetchCategories = async () => {
  const formData = new FormData();
  formData.append("cat_id", "2");

  const response = await axios.post(
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_CATEGORIES,
    formData
  );
  return response.data;
};

const fetchUser = async ({ api_key, MKuserId }) => {
  const formData = new FormData();
  formData.append("user_id", MKuserId);

  const myHeaders = new Headers();
  myHeaders.append("x-api-key", api_key);
  // myHeaders.append("Cookie", "ci_session=fm0fn8rmqsbfm6vr41l4rgsu2d3t35e4");

  const response = await fetch(
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_USER_DATA,
    {
      method: "POST",
      // headers: myHeaders,
      body: formData,
    }
  );

  return response.json();
};

const Header = () => {
  const [open, setopen] = useState(false);

  // Adjust as needed
  const twoColumnMenuStyle = {
    columnCount: 2,
    columnGap: "1rem", // Adjust as needed
  };

  const [useSubCat, setuseSubCat] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loadingBar = useRef(null);
  const cartData = useSelector((state) => state.cartData);
  var api_key = JSON.parse(localStorage.getItem("MKapi_key"));
  var MKuserId = JSON.parse(localStorage.getItem("MKuserId"));
  const productCataegoriesQuery = useQuery("categories", fetchCategories, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
  const userQuery = useQuery(
    ["user", { api_key, MKuserId }],
    () => fetchUser({ api_key, MKuserId }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (productCataegoriesQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [productCataegoriesQuery.isLoading]);

  if (productCataegoriesQuery.error) {
    return <h2>Error: {productCataegoriesQuery.error.message}</h2>;
  }

  if (productCataegoriesQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }

  const getSub = async (newSubCatId) => {
    setLoading(true);
    setuseSubCat("");
    const formData = new FormData();
    formData.append("sub_cat_id", newSubCatId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_ITEMS}`,
        formData
      );
      setuseSubCat(response.data);
    } catch (error) {
      console.error("Error fetching sub categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const navigateToShop = (subid, catid) => {
    console.log("catid", catid, "subid", subid);
    dispatch(productByCategory(catid, subid));
    navigate(`/shop/${subid}`);
    setopen(false);
  };

  const hendleRemoveToCatd = async (id) => {
    const cartIdsArr = remove(id);
    dispatch(productByRemoveCart(cartIdsArr));
    toastSuccess("successfully removed to cart");
  };
  quantityAdd();

  const hendleAccount = () => {
    if (api_key) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  // const navigateToShopM = (subid, catid) => {
  //   console.log("catid", catid, "subid", subid);
  //   dispatch(productByCategory(catid, subid));
  //   navigate(`/shop/${subid}`);
  //   setopen(!open)
  // };

  // console.log(userQuery && userQuery.data.type === 'error',"pppppppppppp")

  let Subtotal =
  cartData.length &&
  cartData.map((item) => +item.sell_price).reduce((prev, next) => prev + next);
  
  Subtotal = Subtotal.toFixed(2);
  return (
    <div className="header_fix">
      <LoadingBar color="#f11946" ref={loadingBar} />
      <header className="header d-blue-bg">
        {/*  <div className="header-top">
          <div className="container 0">
            <div className="header-inner">
              <div className="row align-items-center">
                <div className="col-xl-6 col-lg-7">
                  <div className="header-inner-start">
                    <div className="header__currency border-right">
                                        <div className="s-name">
                                            <span>Language: </span>
                                        </div>
                                        <select>
                                            <option>English</option>
                                            <option>Deutsch</option>
                                            <option>Français</option>
                                            <option>Espanol</option>
                                        </select>
                                    </div> */}
        {/* <div className="header__lang border-right">
                                        <div className="s-name">
                                            <span>Currency: </span>
                                        </div>
                                        <select>
                                            <option> USD</option>
                                            <option>EUR</option>
                                            <option>INR</option>
                                            <option>BDT</option>
                                            <option>BGD</option>
                                        </select>
                                    </div> 
                    <div className="support d-none d-sm-block">
                      <p>
                        Need Help?{" "}
                        <a href="tel:+001123456789">+001 123 456 789</a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-5 d-none d-lg-block">
                  <div className="header-inner-end text-md-end">
                    <div className="ovic-menu-wrapper ovic-menu-wrapper-2">
                      <ul>
                        <li>
                          <Link to={"/about"}> About Us </Link>
                        </li>
                        <li>
                          <Link to={"/contact"}> Order Tracking </Link>
                        </li>
                        <li>
                          {" "}
                          <Link to={"/contact"}> Contact Us </Link>{" "}
                        </li>
                        <li>
                          {" "}
                          <Link to={"/faq"}>FAQs </Link>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="header-mid">
          <div className="container">
            <div className="heade-mid-inner">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-4 col-sm-4">
                  <div className="header__info header__info-2">
                    <div className="logo logo-3">
                      {/* <a href="index.php" className="logo-image"><img src={logo} alt="logo"/></a> */}
                      <Link to={"/"} className="logo-image">
                        <img src={logo} alt="logo" />
                      </Link>
                    </div>
                    <div className="side-menu d-xl-none d-lg-none d-block">
                      <button
                        onClick={() => setopen(!open)}
                        type="button"
                        className="side-menu-btn offcanvas-toggle-btn"
                      >
                        {" "}
                        {open == false ? (
                          <i className="fas fa-bars"></i>
                        ) : (
                          <i class="fa-solid fa-xmark"></i>
                        )}
                      </button>
                    </div>
                    {/* <div class="side-menu mr-20 d-lg-none d-md-none" style={{ position: "relative", top: "-12px" }}>
                      <button onClick={() => setopen(!open)} type="button" className="side-menu-btn offcanvas-toggle-btn">
                        <i className="fas fa-bars"></i>
                      </button>
                    </div> */}
                  </div>
                </div>
                <div className="col-xl-5 col-lg-4 d-none d-lg-block">
                  <div className="header__search">
                    <form action="#">
                      <div className="header__search-box">
                        <input
                          className="search-input search-input-2"
                          type="text"
                          placeholder="I'm shopping for..."
                        />
                        <button
                          className="button button-2 button-3"
                          type="submit"
                        >
                          <i className="fa fa-search"></i>
                        </button>
                      </div>
                      {/* <div className="header__search-cat">
                                            <select>
                                                <option>All Categories</option>
                                                <option>Best Seller Products</option>
                                                <option>Top 10 Offers</option>
                                                <option>New Arrivals</option>
                                                <option>Phones &amp; Tablets</option>
                                                <option>Electronics &amp; Digital</option>
                                                <option>Fashion &amp; Clothings</option>
                                                <option>Jewelry &amp; Watches</option>
                                                <option>Health &amp; Beauty</option>
                                                <option>Sound &amp; Speakers</option>
                                                <option>TV &amp; Audio</option>
                                                <option>Computers</option>
                                            </select>
                                        </div> */}
                    </form>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-8 col-sm-8">
                  <div className="header-action">
                    <div className="block-userlink">
                      {/* <a className="icon-link icon-link-2" href="login.php">
                                        <i className="fa-regular fa-user"></i>
                                        <span className="text">
                                        <span className="sub">Login </span>
                                        My Account </span>
                                        </a> */}
                      <div
                        onClick={hendleAccount}
                        className="icon-link icon-link-2"
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa-regular fa-user"></i>
                        <span className="text">
                          <span className="sub">
                            {userQuery?.data?.type === 'error' ? "Login" : "Logout"}
                          </span>
                          {userQuery?.data?.type === 'error' ? "my account" : userQuery?.data?.name}
                        </span>

                      </div>
                    </div>
                    <div className="block-wishlist action">
                      {/* <a className="icon-link icon-link-2" href="wishlist.php">
                                        <i className="fa-regular fa-heart"></i>
                                        <span className="count count-2">0</span>
                                        <span className="text">
                                        <span className="sub">Favorite</span>
                                        My Wishlist </span>
                                        </a> */}
                      <Link to={"/wishlist"} className="icon-link icon-link-2">
                        <i className="fa-regular fa-heart"></i>
                        <span className="count count-2">0</span>
                        <span className="text">
                          <span className="sub">Favorite</span>
                          My Wishlist{" "}
                        </span>
                      </Link>
                    </div>
                    <div className="block-cart action">
                      <Link to={"/cart"} className="icon-link icon-link-2">
                        {/* <i className="flaticon-shopping-bag"></i> */}
                        <i className="fa-solid fa-bag-shopping"></i>
                        <span className="count count-2">{cartData.length}</span>
                        <span className="text">
                          <span className="sub">Your Cart:</span>
                          ${Subtotal}{" "}
                        </span>
                      </Link>
                      <div className="cart">
                        <div className="cart__mini">
                          <ul className="mb-0">
                            <li>
                              <div className="cart__title">
                                <h4>Your Cart</h4>
                                <span>
                                  ({`${cartData.length} Item in Cart`})
                                </span>
                              </div>
                            </li>
                            <li>
                              {cartData &&
                                cartData.map((item, index) => (
                                  <div
                                    key={index}
                                    className="cart__item d-flex justify-content-between"
                                  >
                                    <div className="cart__inner d-flex">
                                      <div className="cart__thumb">
                                        <Link
                                          to={`/product_details/${encodeURIComponent(
                                            item.title
                                          )}`}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`}
                                            alt="logo"
                                          />
                                        </Link>
                                      </div>
                                      <div className="cart__details">
                                        <Link
                                          to={`/product_details/${encodeURIComponent(
                                            item.title
                                          )}`}
                                        >
                                          <h6>
                                            <a className="a2"> {item.name} </a>
                                          </h6>
                                          <div className="cart__price">
                                            <span>₹{item.sell_price}</span>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>

                                    <div
                                      className="cart__del ps-2"
                                      style={{
                                        cursor: "pointer",
                                        lineHeight: "14px",
                                      }}
                                      onClick={() =>
                                        hendleRemoveToCatd(item.id)
                                      }
                                    >
                                      <i
                                        className="fas fa-trash-alt text-muted"
                                        style={{ fontSize: "12px" }}
                                      ></i>
                                    </div>
                                  </div>
                                ))}
                            </li>
                            <li>
                              {/* <div className="cart__sub d-flex justify-content-between align-items-center">
                                <h6>Subtotal</h6>
                                <span className="cart__sub-total">$255.00</span>
                              </div> */}
                            </li>
                            <li>
                              {/* <a href="cart.php" className="wc-cart mb-10">View cart</a> */}
                              <Link to={"/cart"} className="wc-cart">
                                {" "}
                                View cart{" "}
                              </Link>
                              {/* <Link to={"/checkout"} className="wc-checkout">
                                Checkout
                              </Link> */}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`header__bottom  ${open == true ? "open_header" : ""}`}>
          <div className="container">
            {/* get_sub_categories */}
            <div className="box-items-inner pb-10">
              <div className="d-lg-none d-md-block">
                <h5 className="p-3 mb-0">Products</h5>
              </div>

              {productCataegoriesQuery.data &&
                productCataegoriesQuery.data.map((catitem, index) => (
                  <Dropdown key={index}>
                    <Dropdown.Toggle
                      id="dropdown-basic"
                      className="dropdown-toggle-custom box-item"
                    >
                      <div
                        className="nav_img"
                        onClick={() => getSub(catitem.id)}
                      >
                        {catitem.img !== "" && (
                          <span>
                            <img
                              src={`${process.env.REACT_APP_API_SUB_IMG}/${catitem.img}`}
                              alt=""
                            />
                          </span>
                        )}

                        {catitem.name}
                      </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu-custom">
                      {!loading &&
                        useSubCat &&
                        useSubCat.map((subitem, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() =>
                              navigateToShop(subitem.id, catitem.id)
                            }
                            className="dropdown-item-custom"
                          >
                            {subitem.name}
                          </Dropdown.Item>
                        ))}
                      {loading && <div className="d-block">Loading...</div>}
                    </Dropdown.Menu>
                  </Dropdown>
                ))}
              {/* {open && (

                <div className="d-lg-none d-md-block">
                  <h5 className="p-3 mb-0">Pages</h5>
                  <div className="dropdown">
                    <a href="" className="nav_img">My Account</a>
                    <a href="" className="nav_img">Cart</a>
                    <a href="" className="nav_img">Checkout</a>
                  </div>

                  <h5 className="p-3 mb-0">Information</h5>
                  <div className="dropdown">
                    <a href="" className="nav_img">About Us</a>
                    <a href="" className="nav_img">Contact Us</a>
                    <a href="" className="nav_img">Terms & Condition</a>
                    <a href="" className="nav_img">Privacy Policy</a>
                    <a href="" className="nav_img">Help Center</a>
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </header>
      {/* {open && (
        <div className="" >
   
          <div class="main-menu">
            <nav id="mobile-menu-2">
              <ul>
                {productCataegoriesQuery.data &&
                  productCataegoriesQuery.data.map((catitem, index) => (
                    <li key={index}>
                      <a className="active" onClick={() => getSub(catitem.id)}>{catitem.name} <i className="far fa-angle-down"></i></a>
                      <ul className="megamenu-1">
                        {!loading && useSubCat && useSubCat.map((subitem, index) => (
                          <ul className="mega-item" key={index}>
                            <li><a onClick={() =>
                              navigateToShopM(subitem.id, catitem.id)
                            }>{subitem.name}</a></li>

                          </ul>
                        ))}
                      </ul>
                    </li>
                  ))}
              </ul>


            </nav>
          </div>

   
          <div class="offcanvas__area">
            <div class="offcanvas__wrapper">
              <div class="offcanvas__close">
                <button class="offcanvas__close-btn" id="offcanvas__close-btn">
                  <i class="fal fa-times"></i>
                </button>
              </div>
              <div class="offcanvas__content">
                <div class="offcanvas__logo mb-40">
                  <a href="index.html">
                    <img src="viewer_assets/img/logo/logo.png" alt="logo" height="24" />
                  </a>
                </div>
                <div class="offcanvas__search mb-25">
                  <form action="#">
                    <input type="text" placeholder="What are you searching for?" />
                    <button type="submit" ><i class="far fa-search"></i></button>
                  </form>
                </div>

                <div class="mobile-menu-2"></div>
                <div class="offcanvas__action">
                </div>
              </div>
            </div>
          </div>
 
          <div class="body-overlay"></div>
       
        </div>
      )} */}
    </div>
  );
};

export default Header;
