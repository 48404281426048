import { Set_Cart_Product_List, Emti_Cart } from "./constant";

export const cartData = (data = [], action) => {
  switch (action.type) {
    case Set_Cart_Product_List:
      console.log("cart_Product_List called", action);
      return [...action.data];

    // case Remove_From_Cart:

    //   const remainingItem = data.filter((item) => item.id !== action.data)
    //   return [...remainingItem];

    case Emti_Cart:
      data = [];
      return [...data];

    default:
      return data;
  }
};
