import React, { useState, useEffect, useRef } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useQuery } from 'react-query';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { getOffer } from '../../utils/getOffer';
import { useDispatch, useSelector } from "react-redux";
import { productByAddCart } from "../../redux/productAction";
import { get } from "../../utils/grtIdinlocal";
import { toastSuccess } from "../../utils/notifyCustom"
const Recentproducts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loadingBar = useRef(null);
    const cartData = useSelector((state) => state.cartData);
    const fetchRecentProducts = async () => {
        const response = await axios.post(
            process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_LOAD_RECENT_PRODUCTS,
        );
        return response.data.product;
    };

    const fetchRecentProductsQuery = useQuery('recentproducts', fetchRecentProducts, {
        refetchOnMount: false,
        refetchOnWindowFocus: false
    });

    const startLoading = () => {
        loadingBar.current.continuousStart();
    };

    const finishLoading = () => {
        loadingBar.current.complete();
    };

    useEffect(() => {
        if (fetchRecentProductsQuery.isLoading) {
            startLoading();
        } else {
            finishLoading();
        }
    }, [fetchRecentProductsQuery.isLoading]);

    if (fetchRecentProductsQuery.isLoading) {
        return (
            <div>
                <LoadingBar color="#f11946" ref={loadingBar} />
                {/* <h2>Loading...</h2> */}
            </div>
        );
    }
    console.log("uuuuuddd", fetchRecentProductsQuery.data)

    const hendleAddToCatd = async (id) => {
        if (cartData.find((cartItem) => +cartItem.id == id)) {
            navigate('/cart')
        } else {
            const cartIdsArr = get(id);
            console.log(cartIdsArr);
            dispatch(productByAddCart(cartIdsArr));
            toastSuccess("Successfully Add to cart")
        }
    };


    return (
        <div>
            <LoadingBar color="#f11946" ref={loadingBar} />
            <section className="trending-product-area light-bg-s pt-20 pb-15">
                <div className="container 0">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="section__head d-flex justify-content-between mb-30">
                                <div className="section__title section__title-2">
                                    <h5 className="st-titile">Recent Products</h5>
                                </div>
                                <div className="button-wrap button-wrap-2">
                                    <a>See All Product <i className="fa fa-chevron-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {fetchRecentProductsQuery.data && fetchRecentProductsQuery.data.map((item, index) => (
                            <div key={index} className="col-sm-6 col-md-4 col-lg-4 col-xl-3 col-xxl-3 mb-10">
                                <div className="product__item product__item-2 b-radius-2 mb-20">
                                    <div className="product__thumb fix">
                                        <div className="product-image w-img mb-3">
                                            <Link to={`/product_details/${encodeURIComponent(item.title)}`}>
                                                <img src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`} alt="product" />
                                            </Link>
                                        </div>
                                        <div className="product__offer">
                                            <span
                                                className="discount"
                                                style={{
                                                    display: getOffer(item.price, item.sell_price) == "0" ? 'none' : 'inline'
                                                }}
                                            >
                                                {getOffer(item.price, item.sell_price)}%
                                            </span>
                                        </div>
                                        <div className="product-action product-action-2">
                                            <Link to={`/product_details/${encodeURIComponent(
                                                item.title
                                            )}`}>
                                                <a className="icon-box icon-box-1" data-bs-toggle="modal" data-bs-target="#productModalId">
                                                    <i className="fa fa-eye"></i>
                                                    <i className="fa fa-eye"></i>
                                                </a>
                                            </Link>
                                            <a className="icon-box icon-box-1">
                                                <i className="fa fa-heart"></i>
                                                <i className="fa fa-heart"></i>
                                            </a>
                                            {/* <a href="#" className="icon-box icon-box-1">
                                                <i className="fa fa-layer-group"></i>
                                                <i className="fa fa-layer-group"></i>
                                            </a> */}
                                        </div>
                                    </div>
                                    <div className="product__content product__content-2">
                                        <h6 ><a className='a1' >{`${item.cat_name}, ${item.item_name}`}</a></h6>
                                        <Link to={`/product_details/${encodeURIComponent(item.title)}`}>

                                            <h6>
                                                <a className="a2">{item.name}</a>
                                            </h6>

                                        </Link>
                                        <div className="rating mb-5 mt-10">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            </ul>
                                            {/* <span>(01 review)</span> */}
                                        </div>
                                        <div className="price mb-20">
                                            <span>
                                                ₹{item.sell_price} - <del>₹{item.price}</del>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="product__add-cart text-center">
                                        <button onClick={() => hendleAddToCatd(item.id)} type="button" className={
                                            `w-100 mr-10 ${cartData.find((cartItem) => +cartItem.id == +item.id)
                                                ? "cart-btn-3"
                                                : "cart-btn"
                                            }`
                                        }>
                                            {cartData.find(
                                                (cartItem) => +cartItem.id == +item.id
                                            )
                                                ? "view cart"
                                                : "Add to Cart"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Recentproducts