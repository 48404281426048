import React, { useState } from "react";
import loginbanner from "../viewer_assets/img/banner/page-banner-4.jpg";
import "../css/register.css";
import OtpInput from "react-otp-input";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
function Register() {
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState();
  const [name, setname] = useState();
  const [email, setemail] = useState();
  const [phone, setphone] = useState();
  const [password, setpassword] = useState();
  const [conpassword, setconpassword] = useState();

  const handleButtonClick = async (e) => {
    e.preventDefault();
    console.log(name, email, phone);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_REGISTER_OTP}`,
        formData
      );

      console.log(response.data.message);
      if (response) {
        setShow(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => setShow(false);

  const handleButtonClickOTP = async (e) => {
    e.preventDefault();
    console.log(name, email, phone);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("otp", otp);
      formData.append("password", password);
      formData.append("con_password", conpassword);

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_REGISTER_DATA}`,
        formData
      );

      if (response) {
        console.log(response.data.message);
        setShow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      {/* <!-- page-banner-area-start --> */}
      <div
        class="page-banner-area page-banner-height-2"
        style={{ backgroundImage: `url(${loginbanner})` }}
      >
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="page-banner-content text-center">
                <h4 class="breadcrumb-title">Register</h4>
                <div class="breadcrumb-two">
                  <nav>
                    <nav class="breadcrumb-trail breadcrumbs">
                      <ul class="breadcrumb-menu">
                        <li class="breadcrumb-trail">
                          <Link to={"/"}>
                            <span>Home</span>
                          </Link>
                        </li>
                        <li class="trail-item">
                          <span>Register</span>
                        </li>
                      </ul>
                    </nav>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- page-banner-area-end --> */}

      {/* <!-- account-area-start --> */}
      <div class="account-area mt-70 mb-70">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="basic-login">
                <h5>Register</h5>
                <form>
                  <label for="username">
                    Username <span>*</span>
                  </label>
                  <input
                    id="username"
                    type="text"
                    placeholder="Enter Username"
                    onChange={(e) => setname(e.target.value)}
                  />
                  <label for="email-id">
                    Email Address <span>*</span>
                  </label>
                  <input
                    id="email-id"
                    type="email"
                    placeholder="Email address..."
                    onChange={(e) => setemail(e.target.value)}
                  />
                  <label for="userpass">
                    Phone <span>*</span>
                  </label>
                  <input
                    id="userpass"
                    type="number"
                    placeholder="Enter phone number..."
                    onChange={(e) => setphone(e.target.value)}
                  />
                  <div class="login-action mb-10 fix">
                    <p>
                      Your personal data will be used to support your experience
                      throughout this website, to manage access to your account,
                      and for other purposes described in our{" "}
                      <a>privacy policy</a>.
                    </p>
                  </div>
                  <button class="tp-in-btn w-100" onClick={handleButtonClick}>
                    Register
                  </button>
                  <p class="mt-3 text-center">
                    Already have an account?{" "}
                   
                    <Link to={"/login"} class="text-primary">
                      Create Now
                    </Link>
                    
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- account-area-end -->      */}
      {show && (
        <>
          {/* <div
            id="popup-modal"
            tabIndex="-1"
            className="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
          >
            <div className="relative p-4 w-full max-w-md max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                
              </div>
            </div>
          </div> */}
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              {/* <Modal.Title>OTP Verification</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
              <div className="card otp_div">
                <form style={{ padding: "16px" }}>
                  <h2 className="title">OTP Verification</h2>

                  <OtpInput
                    containerStyle=" h-20"
                    // inputStyle={
                    //   "text-center outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 ring-blue-700"
                    // }
                    inputStyle="inputStyle"
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />

                  <div className="email-login">
                    <label htmlFor="password">
                      <b>Password</b>
                    </label>
                    <input
                      onChange={(e) => setpassword(e.target.value)}
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      required
                    />
                    <label htmlFor="password">
                      <b>Confirm password</b>
                    </label>
                    <input
                      type="password"
                      placeholder="Enter Password"
                      name="password"
                      required
                      onChange={(e) => setconpassword(e.target.value)}
                    />
                  </div>
                  <button
                    onClick={handleButtonClickOTP}
                    className="cta-btn"
                    type="submit"
                  >
                    Submitted
                  </button>
                </form>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </div>
  );
}

export default Register;
