import loginbanner from "../viewer_assets/img/banner/page-banner-4.jpg";
import { quantityShowData } from "../utils/quantity";
import { rzp } from "../utils/rzp";
import axios from "axios";
import { toastSuccess } from "../utils/notifyCustom"
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { useDispatch } from "react-redux";
import { productByEmtiCart } from "../redux/productAction";

function Checkout() {
  const dispatch = useDispatch();
  const [pin, setpin] = useState('')
  const [city, setcity] = useState('')
  const [State, setState] = useState('')
  const [address, setaddress] = useState('')
  const [Landmark, setLandmark] = useState('')


  const [selectedAddressId, setSelectedAddressId] = useState(null);

  const loadingBar = useRef(null);
  var MKuserId = JSON.parse(localStorage.getItem("MKuserId"));
  const [data, setData] = useState([]);
  const [totalAmount, settotalAmount] = useState();
  const [subtotal, setsubtotal] = useState();
  const [ids, setids] = useState();
  const [quantitys, setquantitys] = useState();
  const [payment_mode, setpayment_mode] = useState("online");


  // add user
  const fetchUserAddress = async () => {
    var MKuserId = JSON.parse(localStorage.getItem("MKuserId"))
    console.log(MKuserId, "yyyyyyyyy");
    const formData = new FormData();
    formData.append("user_id", MKuserId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_ADDRESS_DATA}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user address:", error);
      throw error;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const result = await quantityShowData();
      setData(result);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data && data.length > 0) {
      let totalAmount = data
        .map((item) => item.quantity * parseFloat(item.price))
        .reduce((prev, next) => prev + next, 0);

      let Subtotal =
        data.length &&
        data.map((item) => +item.price).reduce((prev, next) => prev + next);
      Subtotal = Subtotal.toFixed(2);
      totalAmount = totalAmount.toFixed(2);
      settotalAmount(totalAmount);
      setsubtotal(Subtotal);
      const ids = data.map((product) => product.id).join(", ");
      const quantities = data.map((product) => product.quantity).join(", ");
      setids(ids);
      setquantitys(quantities);
    } else {
      settotalAmount(0); // or any default value you prefer
    }
  }, [totalAmount]);

  const userAddressQuery = useQuery(["fetchUserAddres",], () => fetchUserAddress(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  }
  );

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (userAddressQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [userAddressQuery.isLoading]);

  if (userAddressQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }

  // Call the function to store data in the state


  if (userAddressQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  console.log("Total", payment_mode, userAddressQuery.data);



  const adduserAddress = (item) => {
    setSelectedAddressId(item.id);
    console.log(item, "ddddddddddddddddddd")
    setpin(item.pin)
    setcity(item.city)
    setState(item.state)
    setaddress(item.address)
    setLandmark(item.land_mark)
  }

  const handelPay = async (e) => {
    e.preventDefault();
    console.log(ids);
    console.log(quantitys);

    if (payment_mode === "online") {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("user_id", MKuserId);
      data.append("all_product_id", ids);
      data.append("all_product_qty", quantitys);
      data.append("shipping_city", city);
      data.append("shipping_state", State);
      data.append("shipping_address", address);
      data.append("shipping_pin", pin);
      data.append("shipping_land_mark", Landmark);
      data.append("payment_mode", "online");

      await axios(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ORDER}`,
        {
          method: "POST",
          data: data,
        }
      ).then((res) => {
        console.log(res.data);
        setpin('')
        setcity('')
        setState('')
        setaddress('')
        setLandmark('')
        dispatch(productByEmtiCart());
        var rzp1 = rzp(totalAmount);
        rzp1.open();
      });
    } else {
      const FormData = require("form-data");
      let data = new FormData();
      data.append("user_id", MKuserId);
      data.append("all_product_id", ids);
      data.append("all_product_qty", quantitys);
      data.append("shipping_city", city);
      data.append("shipping_state", State);
      data.append("shipping_address", address);
      data.append("shipping_pin", pin);
      data.append("shipping_land_mark", Landmark);
      data.append("payment_mode", "cash");
      await axios(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ORDER}`,
        {
          method: "POST",
          data: data,
        }
      ).then((res) => {
        console.log(res.data);
        setpin('')
        setcity('')
        setState('')
        setaddress('')
        setLandmark('')
        dispatch(productByEmtiCart());
        toastSuccess(res.data.message);
      });
    }
  };

  return (
    <div>
      <LoadingBar color="#f11946" ref={loadingBar} />
      {/* <!-- page-banner-area-start --> */}
      {/*<div class="page-banner-area page-banner-height-2" style={{ backgroundImage: `url(${loginbanner})` }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-banner-content text-center">
                                <h4 class="breadcrumb-title">Checkout</h4>
                                <div class="breadcrumb-two">
                                    <nav>
                                        <nav class="breadcrumb-trail breadcrumbs">
                                            <ul class="breadcrumb-menu">
                                                <li class="breadcrumb-trail">
                                                    <Link to={"/"}><span>Home</span></Link>
                                                </li>
                                                <li class="trail-item">
                                                    <span>Checkout</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
      {/* <!-- page-banner-area-end --> */}

      {/* <!-- coupon-area-start --> */}
      <section class="coupon-area py-3">
        <div class="container">
          <div class="row">
            <div className="col-12">
              <h5 class="mb-3 mt-lg-0 mt-md-0 mt-2">Checkout</h5>
            </div>
            <div class="col-md-6">
              <div class="coupon-accordion">
                {/* <!-- ACCORDION START --> */}
                <h3>
                  Returning customer?{" "}
                  <span id="showlogin">Click here to login</span>
                </h3>
                <div id="checkout-login" class="coupon-content">
                  <div class="coupon-info">
                    <p class="coupon-text">
                      Quisque gravida turpis sit amet nulla posuere lacinia.
                      Cras sed est sit amet ipsum luctus.
                    </p>
                    <form action="#">
                      <p class="form-row-first">
                        <label>
                          Username or email <span class="required">*</span>
                        </label>
                        <input type="text" />
                      </p>
                      <p class="form-row-last">
                        <label>
                          Password <span class="required">*</span>
                        </label>
                        <input type="text" />
                      </p>
                      <p class="form-row">
                        <button class="tp-btn-h1" type="submit">
                          Login
                        </button>
                        <label>
                          <input type="checkbox" />
                          Remember me
                        </label>
                      </p>
                      <p class="lost-password">
                        <a href="#">Lost your password?</a>
                      </p>
                    </form>
                  </div>
                </div>
                {/* <!-- ACCORDION END --> */}
              </div>
            </div>
            <div class="col-md-6">
              <div class="coupon-accordion">
                {/* <!-- ACCORDION START --> */}
                <h3>
                  Have a coupon?{" "}
                  <span id="showcoupon">Click here to enter your code</span>
                </h3>
                <div id="checkout_coupon" class="coupon-checkout-content">
                  <div class="coupon-info">
                    <form action="#">
                      <p class="checkout-coupon">
                        <input type="text" placeholder="Coupon Code" />
                        <button class="tp-btn-h1" type="submit">
                          Apply Coupon
                        </button>
                      </p>
                    </form>
                  </div>
                </div>
                {/* <!-- ACCORDION END --> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- coupon-area-end --> */}

      {/* <!-- checkout-area-start --> */}
      <section class="checkout-area pb-85">
        <div class="container">
          <form action="#">
            <div class="row">
              <div class="col-lg-6">
                <div class="checkbox-form">
                  <h3>Billing Details</h3>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="country-select">
                        <label>
                          Country <span class="required">*</span>
                        </label>
                        <select style={{ display: "none" }}>
                          <option onChange="volvo">bangladesh</option>
                          <option onChange="saab">Algeria</option>
                          <option onChange="mercedes">Afghanistan</option>
                          <option onChange="audi">Ghana</option>
                          <option onChange="audi2">Albania</option>
                          <option onChange="audi3">Bahrain</option>
                          <option onChange="audi4">Colombia</option>
                          <option onChange="audi5">Dominican Republic</option>
                        </select>
                        <div class="nice-select" tabIndex="0">
                          <span class="current">bangladesh</span>
                          <ul class="list">
                            <li data-onChange="volvo" class="option selected">
                              bangladesh
                            </li>
                            <li data-onChange="saab" class="option">
                              Algeria
                            </li>
                            <li data-onChange="mercedes" class="option">
                              Afghanistan
                            </li>
                            <li data-onChange="audi" class="option">
                              Ghana
                            </li>
                            <li data-onChange="audi2" class="option">
                              Albania
                            </li>
                            <li data-onChange="audi3" class="option">
                              Bahrain
                            </li>
                            <li data-onChange="audi4" class="option">
                              Colombia
                            </li>
                            <li data-onChange="audi5" class="option">
                              Dominican Republic
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          First Name <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          Last Name <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="checkout-form-list">
                        <label>Company Name</label>
                        <input type="text" placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="checkout-form-list">
                        <label>
                          Address <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="Street address" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="checkout-form-list">
                        <input
                          type="text"
                          placeholder="Apartment, suite, unit etc. (optional)"
                        />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="checkout-form-list">
                        <label>
                          Town / City <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="Town / City" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          State / County <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          Postcode / Zip <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="Postcode / Zip" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          Email Address <span class="required">*</span>
                        </label>
                        <input type="email" placeholder="" />
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="checkout-form-list">
                        <label>
                          Phone <span class="required">*</span>
                        </label>
                        <input type="text" placeholder="Postcode / Zip" />
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="checkout-form-list create-acc">
                        <input id="cbox" type="checkbox" />
                        <label>Create an account?</label>
                      </div>
                      <div
                        id="cbox_info"
                        class="checkout-form-list create-account"
                      >
                        <p>
                          Create an account by entering the information below.
                          If you are a returning customer please login at the
                          top of the page.
                        </p>
                        <label>
                          Account password <span class="required">*</span>
                        </label>
                        <input type="password" placeholder="password" />
                      </div>
                    </div>
                  </div>
                  <div class="different-address">
                    <div class="ship-different-title">
                      <h3>
                        <label>Ship to a different address?</label>
                        <input id="ship-box" type="checkbox" />
                      </h3>
                    </div>
                    <div id="ship-box-info">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="country-select">
                            <label>
                              Country <span class="required">*</span>
                            </label>
                            <select style={{ display: "none" }}>
                              <option onChange="volvo">bangladesh</option>
                              <option onChange="saab">Algeria</option>
                              <option onChange="mercedes">Afghanistan</option>
                              <option onChange="audi">Ghana</option>
                              <option onChange="audi2">Albania</option>
                              <option onChange="audi3">Bahrain</option>
                              <option onChange="audi4">Colombia</option>
                              <option onChange="audi5">
                                Dominican Republic
                              </option>
                            </select>
                            <div class="nice-select" tabIndex="0">
                              <span class="current">bangladesh</span>
                              <ul class="list">
                                <li
                                  data-onChange="volvo"
                                  class="option selected"
                                >
                                  bangladesh
                                </li>
                                <li data-onChange="saab" class="option">
                                  Algeria
                                </li>
                                <li data-onChange="mercedes" class="option">
                                  Afghanistan
                                </li>
                                <li data-onChange="audi" class="option">
                                  Ghana
                                </li>
                                <li data-onChange="audi2" class="option">
                                  Albania
                                </li>
                                <li data-onChange="audi3" class="option">
                                  Bahrain
                                </li>
                                <li data-onChange="audi4" class="option">
                                  Colombia
                                </li>
                                <li data-onChange="audi5" class="option">
                                  Dominican Republic
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              First Name <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              Last Name <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="checkout-form-list">
                            <label>Company Name</label>
                            <input type="text" placeholder="" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="checkout-form-list">
                            <label>
                              Address <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="Street address" />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="checkout-form-list">
                            <input
                              type="text"
                              placeholder="Apartment, suite, unit etc. (optional)"
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="checkout-form-list">
                            <label>
                              Town / City <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="Town / City" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              State / County <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              Postcode / Zip <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="Postcode / Zip" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              Email Address <span class="required">*</span>
                            </label>
                            <input type="email" placeholder="" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="checkout-form-list">
                            <label>
                              Phone <span class="required">*</span>
                            </label>
                            <input type="text" placeholder="Postcode / Zip" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="order-notes">
                      <div class="checkout-form-list">
                        <label>Order Notes</label>
                        <textarea
                          id="checkout-mess"
                          cols="30"
                          rows="10"
                          placeholder="Notes about your order, e.g. special notes for delivery."
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="your-order mb-30 ">
                  <h3>Your order</h3>
                  <div class="your-order-table table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th class="product-name">Product</th>
                          <th class="product-total text-end text-dark">
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data &&
                          data.map((item, index) => (
                            <tr class="cart_item" key={index}>
                              <td class="product-name">
                                <p>{item.productName}</p>
                                <strong class="product-quantity">
                                  ₹{item.price} × {item.quantity}
                                </strong>
                              </td>
                              <th class="product-total text-end">
                                <strong class="amount">
                                  ₹{item.price * item.quantity}
                                </strong>
                              </th>
                            </tr>
                          ))}
                      </tbody>
                      <tfoot>
                        <tr class="cart-subtotal">
                          <th className="text-dark">
                            <strong>Cart Subtotal</strong>
                          </th>
                          <td className="text-end text-dark">
                            <strong>
                              <span class="amount">₹{subtotal}</span>
                            </strong>
                          </td>
                        </tr>
                        {/* <tr class="shipping">
                                            <th>Shipping</th>
                                            <td>
                                                <ul>
                                                    <li>
                                                        <input type="radio" name="shipping"/>
                                                        <label>
                                                            Flat Rate: <span class="amount">$7.00</span>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" name="shipping"/>
                                                        <label>Free Shipping:</label>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr> */}
                        <tr class="order-total">
                          <th>
                            <strong>Order Total</strong>
                          </th>
                          <td className="text-end">
                            <strong>
                              <span class="amount">₹{totalAmount}</span>
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  {userAddressQuery.data.map((item, index) => (
                    <div key={index} class="w-50" onClick={() => adduserAddress(item)}>
                      <input
                        className="mt-0 me-2 accordion-input"
                        type="radio"
                        id={`add-${index}`}
                        name="userAdd"
                        checked={selectedAddressId === item.id}
                        onChange={() => adduserAddress(item)}
                      />
                      <label
                        className="d-flex align-items-center"
                        htmlFor={`add-${index}`}
                      >
                        <h6 className="mb-0">{item.state}, {item.city}, {item.address}, {item.pin}</h6>
                      </label>
                    </div>
                  ))}

                  <hr className="mt-2" />
                  <div class="payment-method mt-0">
                    <div
                      class="d-flex align-items-center"
                      id="checkoutAccordion"
                    >
                      <div class="w-50" onClick={() => setpayment_mode("cash")}>
                        <label
                          className="d-flex align-items-center"
                          for="cashOnDelivery"
                        >
                          <input
                            className="mt-0 me-2"
                            type="radio"
                            id="cashOnDelivery"
                            name="paymentMethod"
                            class="accordion-input"
                            checked
                          />
                          Cash on Delivery
                        </label>
                      </div>
                      <div
                        class="w-50"
                        onClick={() => setpayment_mode("online")}
                      >
                        <label
                          className="d-flex align-items-center"
                          for="online"
                        >
                          <input
                            className="mt-0 me-2"
                            type="radio"
                            id="online"
                            name="paymentMethod"
                            class="accordion-input"
                            checked
                          />
                          Online
                        </label>
                      </div>
                    </div>
                    <div class="order-button-payment mt-3">
                      <button
                        type="submit"
                        class="tp-btn-h1"
                        onClick={handelPay}
                      >
                        Place Order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* <!-- checkout-area-end --> */}
    </div>
  );
}

export default Checkout;
