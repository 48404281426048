import blogbanner from '../viewer_assets/img/banner/page-banner-4.jpg'
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";

function Blog() {

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        })

    }, [])

    const loadingBar = useRef(null);

    const fetchbloglist = async () => {
        const formData = new FormData();
        formData.append("user_id", "1");

        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_BLOG_LIST}`,
            formData
        );
        return response.data;
    };

    const bloglistQuery = useQuery(
        "fetchbloglist",
        fetchbloglist
    );

    const fetchRecentBlog = async () => {
        const formData = new FormData();
        formData.append("limit", "3");
        const response = await axios.post(
            `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_GET_RECENT_BLOG}`,
            formData
        );
        return response.data;
    };

    const recentBlogQuery = useQuery(["recentBlog"], () =>
        fetchRecentBlog()
    );

    const startLoading = () => {
        loadingBar.current.continuousStart();
    };

    const finishLoading = () => {
        loadingBar.current.complete();
    };

    useEffect(() => {
        if (bloglistQuery.isLoading || recentBlogQuery.isLoading) {
            startLoading();
        } else {
            finishLoading();
        }
    }, [bloglistQuery.isLoading, recentBlogQuery.isLoading]);

    if (bloglistQuery.isLoading || recentBlogQuery.isLoading) {
        return (
            <div>
                <LoadingBar color="#f11946" ref={loadingBar} />
                {/* <h2>Loading...</h2> */}
            </div>
        );
    }
    console.log("uuuuudddkkkkkk", bloglistQuery.data);

    return (
        <div>
            <LoadingBar color="#f11946" ref={loadingBar} />
            {/* <!-- page-banner-area-start --> */}
            <div class="page-banner-area page-banner-height-2" style={{ backgroundImage: `url(${blogbanner})` }}>
                <div class="container">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="page-banner-content text-center">
                                <h4 class="breadcrumb-title">Blog</h4>
                                <div class="breadcrumb-two">
                                    <nav>
                                        <nav class="breadcrumb-trail breadcrumbs">
                                            <ul class="breadcrumb-menu">
                                                <li class="breadcrumb-trail">
                                                    <Link to={"/"}><span>Home</span></Link>
                                                </li>
                                                <li class="trail-item">
                                                    <span>Blog</span>
                                                </li>
                                            </ul>
                                        </nav>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- page-banner-area-end --> */}

            {/* <!-- news-detalis-area-start --> */}
            <div class="blog-area mt-120 mb-75">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-7">
                            <div class="row">
                                {bloglistQuery.data &&
                                    bloglistQuery.data.map((item, index) => (
                                        <div key={index} className="col-xl-6">
                                            <div className="single-smblog mb-30 d-flex flex-column h-100">
                                                <div className="smblog-thum">
                                                    <div className="blog-image w-img">
                                                        <Link to={`/blog_details/${item.id}`}>
                                                            <img src={`${process.env.REACT_APP_API_BLOG_IMG}/${item.blog_img}`} alt="logo" />
                                                        </Link>
                                                    </div>
                                                    <div className="blog-tag blog-tag-2">
                                                        <a>{item.category_name}</a>
                                                    </div>
                                                </div>
                                                <div className="smblog-content smblog-content-3 flex-grow-1">
                                                    <h6><Link to={`/blog_details/${item.id}`}>{item.blog_title}</Link></h6>
                                                    {/* <span className="author mb-10">Category <a>{item.category_name}</a></span> */}
                                                    <p dangerouslySetInnerHTML={{ __html: item.blog_details }}></p>

                                                    <div className="smblog-foot pt-15">
                                                        <div className="post-readmore">
                                                            <Link to={`/blog_details/${item.id}`}> Read More <span className="icon"></span></Link>
                                                        </div>
                                                        <div className="post-date">
                                                            <a href="blog-details.php">{item.publish_date}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {/* <div class="col-xl-6">
                        <div class="single-smblog mb-30">
                            <div class="smblog-thum">
                                <div class="blog-image w-img">
                                    <a href="blog-details.php"><img src={blogbanner2} alt=""/></a>
                                </div>
                                <div class="blog-tag blog-tag-2">
                                    <a href="blog.php">Food Beverage</a>
                                </div>
                            </div>
                            <div class="smblog-content smblog-content-3">
                                <h6><a href="blog-details.php">Delicious Grilled Pork For These Family Reunions By The Charcoal Stove</a></h6>
                                <span class="author mb-10">posted by <a href="#">Maxon</a></span>
                                <p>It is accompanied by a case that can contain up to three different diffusers and can be used for dry storage of loose tea. The perfect way to enjoy brewing tea on fruit to...</p>
                                <div class="smblog-foot pt-15">
                                    <div class="post-readmore">
                                        <a href="blog-details.php"> Read More <span class="icon"></span></a>
                                    </div>
                                    <div class="post-date">
                                        <a href="blog-details.php">Jan 24, 2022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="single-smblog mb-30">
                            <div class="smblog-thum">
                                <div class="blog-image w-img">
                                    <a href="blog-details.php"><img src={blogbanner3} alt=""/></a>
                                </div>
                                <div class="blog-tag blog-tag-2">
                                    <a href="blog.php">Food Beverage</a>
                                </div>
                            </div>
                            <div class="smblog-content smblog-content-3">
                                <h6><a href="blog-details.php">Delicious Seasoned Lobster Suitable For Family Summer Holidays</a></h6>
                                <span class="author mb-10">posted by <a href="#">Elana</a></span>
                                <p>It is accompanied by a case that can contain up to three different diffusers and can be used for dry storage of loose tea. The perfect way to enjoy brewing tea on fruit to...</p>
                                <div class="smblog-foot pt-15">
                                    <div class="post-readmore">
                                        <a href="blog-details.php"> Read More <span class="icon"></span></a>
                                    </div>
                                    <div class="post-date">
                                        <a href="blog-details.php">Jan 24, 2022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="single-smblog mb-30">
                            <div class="smblog-thum">
                                <div class="blog-image w-img">
                                    <a href="blog-details.php"><img src={blogbanner4} alt=""/></a>
                                </div>
                                <div class="blog-tag blog-tag-2">
                                    <a href="blog.php">Food Beverage</a>
                                </div>
                            </div>
                            <div class="smblog-content smblog-content-3">
                                <h6><a href="blog-details.php">Grilled Seafood With Delicious and Vegetables and Fruits With Friends</a></h6>
                                <span class="author mb-10">posted by <a href="#">Andrew</a></span>
                                <p>It is accompanied by a case that can contain up to three different diffusers and can be used for dry storage of loose tea. The perfect way to enjoy brewing tea on fruit to...</p>
                                <div class="smblog-foot pt-15">
                                    <div class="post-readmore">
                                        <a href="blog-details.php"> Read More <span class="icon"></span></a>
                                    </div>
                                    <div class="post-date">
                                        <a href="blog-details.php">Jan 24, 2022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="single-smblog mb-30">
                            <div class="smblog-thum">
                                <div class="blog-image w-img">
                                    <a href="blog-details.php"><img src={blogbanner5} alt=""/></a>
                                </div>
                                <div class="blog-tag blog-tag-2">
                                    <a href="blog.php">Digital</a>
                                </div>
                            </div>
                            <div class="smblog-content smblog-content-3">
                                <h6><a href="blog-details.php">Do you know how to people wear headphones properly?</a></h6>
                                <span class="author mb-10">posted by <a href="#">Iqbal</a></span>
                                <p>It is accompanied by a case that can contain up to three different diffusers and can be used for dry storage of loose tea. The perfect way to enjoy brewing tea on fruit to...</p>
                                <div class="smblog-foot pt-15">
                                    <div class="post-readmore">
                                        <a href="blog-details.php"> Read More <span class="icon"></span></a>
                                    </div>
                                    <div class="post-date">
                                        <a href="blog-details.php">Jan 24, 2022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div class="single-smblog mb-30">
                            <div class="smblog-thum">
                                <div class="blog-image w-img">
                                    <a href="blog-details.php"><img src={blogbanner6} alt=""/></a>
                                </div>
                                <div class="blog-tag blog-tag-2">
                                    <a href="blog.php">Digital</a>
                                </div>
                            </div>
                            <div class="smblog-content smblog-content-3">
                                <h6><a href="blog-details.php">Laptops Become More Common in Everyday Life, why it usefull?</a></h6>
                                <span class="author mb-10">posted by <a href="#">Moris</a></span>
                                <p>It is accompanied by a case that can contain up to three different diffusers and can be used for dry storage of loose tea. The perfect way to enjoy brewing tea on fruit to...</p>
                                <div class="smblog-foot pt-15">
                                    <div class="post-readmore">
                                        <a href="blog-details.php"> Read More <span class="icon"></span></a>
                                    </div>
                                    <div class="post-date">
                                        <a href="blog-details.php">Jan 24, 2022</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="basic-pagination text-center pt-30 pb-30">
                                        <nav>
                                            <ul>
                                                <li>
                                                    <a href="blog.php" class="active">1</a>
                                                </li>
                                                <li>
                                                    <a href="blog.php">2</a>
                                                </li>
                                                <li>
                                                    <a href="blog.php">3</a>
                                                </li>
                                                <li>
                                                    <a href="blog.php">5</a>
                                                </li>
                                                <li>
                                                    <a href="blog.php">6</a>
                                                </li>
                                                <li>
                                                    <a href="shop.php">
                                                        <i class="fa fa-angle-double-right"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4 col-lg-5">
                            <div class="news-sidebar pl-10">
                                <div class="row">
                                    <div class="col-lg-12 col-md-12">
                                        <div class="widget">
                                            <h6 class="sidebar-title"> Search Here</h6>
                                            <div class="n-sidebar-search">
                                                <input type="text" placeholder="Search your keyword..." />
                                                <a href="#"><i class="fa fa-search"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12">
                                        <div class="widget">
                                            <h6 class="sidebar-title">Recent Blog</h6>
                                            <div class="n-sidebar-feed">
                                                <ul>
                                                    {recentBlogQuery.data &&
                                                        recentBlogQuery.data.map((item, index) => (
                                                            <li key={index}>
                                                                <div className="feed-number">
                                                                    <Link to={`/blog_details/${item.id}`}><img src={`${process.env.REACT_APP_API_BLOG_IMG}/${item.blog_img}`} alt="" /></Link>
                                                                </div>
                                                                <div className="feed-content">
                                                                    <h6><Link to={`/blog_details/${item.id}`}>{item.blog_title}</Link></h6>
                                                                    <span className="feed-date">
                                                                        <i className="fa fa-calendar-alt"></i> {item.publish_date}
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-lg-12 col-md-6">
                                        <div class="widget">
                                            <h6 class="sidebar-title">Categories</h6>
                                            <ul class="n-sidebar-categories">
                                                <li>
                                                    <a href="blog-details.php">
                                                        <div class="single-category p-relative mb-10">
                                                            Business
                                                            <span class="category-number">26</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.php">
                                                        <div class="single-category p-relative mb-10">
                                                            consultant
                                                            <span class="category-number">30</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.php">
                                                        <div class="single-category p-relative mb-10">
                                                            Creative
                                                            <span class="category-number">71</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.php">
                                                        <div class="single-category p-relative mb-10">
                                                            UI/UX
                                                            <span class="category-number">56</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="blog-details.php">
                                                        <div class="single-category p-relative">
                                                            Texhnology
                                                            <span class="category-number">60</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                                    {/* <div class="col-lg-12 col-md-6">
                                        <div class="widget">
                                            <h6 class="sidebar-title">Instagram Feeds</h6>
                                            <div class="dktags">
                                                <a class="single-tag" href="#">Popular</a>
                                                <a class="single-tag" href="#">Design</a>
                                                <a class="single-tag" href="#">Usability</a>
                                                <a class="single-tag" href="#">Icon</a>
                                                <a class="single-tag" href="#">Kit</a>
                                                <a class="single-tag" href="#">Business</a>
                                                <a class="single-tag" href="#">Keypad</a>
                                                <a class="single-tag" href="#">Tech</a>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- news-detalis-area-end  --> */}
        </div>
    )
}

export default Blog