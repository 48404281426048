import doubaleimg1 from '../../viewer_assets/img/features-product/fp-2.jpg'
import doubaleimg2 from '../../viewer_assets/img/features-product/fp-3.jpg'
import doubaleimg3 from '../../viewer_assets/img/features-product/fp-6.jpg'
import doubaleimg4 from '../../viewer_assets/img/features-product/fp-7.jpg'
import doubaleimg5 from '../../viewer_assets/img/features-product/fp-8.jpg'
import doubaleimg6 from '../../viewer_assets/img/features-product/fp-9.jpg'
// import doubaleimg7 from '../viewer_assets/img/features-product/fp-10.jpg'
// import doubaleimg8 from '../viewer_assets/img/features-product/fp-11.jpg'
// import doubaleimg9 from '../viewer_assets/img/features-product/fp-12.jpg'
import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { getOffer } from '../../utils/getOffer'
const fetchDealsofthedayHotProducts = async () => {
    const formData = new FormData();
    formData.append("q_type", "hot_tranding");

    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_SELECTED_PRODUCTS}`,
        formData
    );
    return response.data.product;
};

const fetchDealsofthedayOnSaleProducts = async () => {
    const formData = new FormData();
    formData.append("q_type", "on_sale");

    const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_LOAD_SELECTED_PRODUCTS}`,
        formData
    );
    return response.data.product;
};

const HotOnSale = () => {
    const loadingBar = useRef(null);

    const hotproductDealsofthedayQuery = useQuery(
        "dealsofthedayHotproducts",
        fetchDealsofthedayHotProducts,{
            refetchOnMount: false,
        refetchOnWindowFocus: false

        }
    );
    const OnSaleproductDealsofthedayQuery = useQuery(
        "dealsofthedayOnSaleproducts",
        fetchDealsofthedayOnSaleProducts,{
            refetchOnMount: false,
        refetchOnWindowFocus: false

        }
    );

    const startLoading = () => {
        loadingBar.current.continuousStart();
    };

    const finishLoading = () => {
        loadingBar.current.complete();
    };

    useEffect(() => {
        if (hotproductDealsofthedayQuery.isLoading || OnSaleproductDealsofthedayQuery.isLoading) {
            startLoading();
        } else {
            finishLoading();
        }
    }, [hotproductDealsofthedayQuery.isLoading || OnSaleproductDealsofthedayQuery.isLoading]);

    if (hotproductDealsofthedayQuery.isLoading || OnSaleproductDealsofthedayQuery.isLoading) {
        return (
            <div>
                <LoadingBar color="#f11946" ref={loadingBar} />
                {/* <h2>Loading...</h2> */}
            </div>
        );
    }
    return (
        <div className="doubale-product-area light-bg-s pt-20 pb-40">
            <LoadingBar color="#f11946" ref={loadingBar} />
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section__head d-flex justify-content-between mb-30">
                                    <div className="section__title section__title-2">
                                        <h5 className="st-titile">Hot Trending Products</h5>
                                    </div>
                                    <div className="button-wrap button-wrap-2">
                                        <a href="product.html">See All <i className="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {hotproductDealsofthedayQuery.data &&
                                hotproductDealsofthedayQuery.data.map((item, index) => (
                                    <div className="col-xl-12">
                                        <div className="single-features-item b-radius-2 mb-20">
                                            <div className="row  g-0 align-items-center">
                                                <div className="col-4">
                                                    <div className="features-thum">
                                                        <div className="features-product-image w-img me-3">
                                                            <Link to={`/product_details/${encodeURIComponent(
                                                                item.title
                                                            )}`} ><img src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`} alt="logo" style={{
                                                                // width: "100%",
                                                                height: "90px",
                                                                maxHeight: "90px",
                                                                objectFit: "contain"
                                                            }} /></Link>
                                                        </div>
                                                        <div className="product__offer">
                                                            <span
                                                                className="discount"
                                                                style={{
                                                                    display:
                                                                        getOffer(item.price, item.sell_price) == "0"
                                                                            ? "none"
                                                                            : "inline",
                                                                }}
                                                            >
                                                                {getOffer(item.price, item.sell_price)}%
                                                            </span>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-8">
                                                    <div className="product__content product__content-d product__content-d-2">
                                                        <h6><Link className='a2' to={`/product_details/${encodeURIComponent(
                                                            item.title
                                                        )}`}>{item.name}</Link></h6>
                                                        <div className="rating mb-5">
                                                            <ul>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            </ul>
                                                            {/* <span>(01 review)</span> */}
                                                        </div>
                                                        <div className="price mb-20">
                                                            <span>
                                                                ₹{item.sell_price} - <del>₹{item.price}</del>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="section__head d-flex justify-content-between mb-30">
                                    <div className="section__title section__title-2">
                                        <h5 className="st-titile">On-sale Products</h5>
                                    </div>
                                    <div className="button-wrap button-wrap-2">
                                        <a href="product.html">See All <i className="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {OnSaleproductDealsofthedayQuery.data &&
                            OnSaleproductDealsofthedayQuery.data.map((item, index) => (
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="single-features-item b-radius-2 mb-20">
                                            <div className="row  g-0 align-items-center">
                                                <div className="col-4">
                                                    <div className="features-thum">
                                                        <div className="features-product-image w-img me-3">
                                                            <Link to={`/product_details/${encodeURIComponent(
                                                                item.title
                                                            )}`} ><img src={`${process.env.REACT_APP_API_PRODUCTS_IMG}/${item.main_img}`} alt="logo" style={{
                                                                // width: "100%",
                                                                height: "90px",
                                                                maxHeight: "90px",
                                                                objectFit: "contain"
                                                            }} /></Link>
                                                            <div className="product__offer">
                                                                <span
                                                                    className="discount"
                                                                    style={{
                                                                        display:
                                                                            getOffer(item.price, item.sell_price) == "0"
                                                                                ? "none"
                                                                                : "inline",
                                                                    }}
                                                                >
                                                                    {getOffer(item.price, item.sell_price)}%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-8">
                                                    <div className="product__content product__content-d product__content-d-2">
                                                        <h6><Link className='a2' to={`/product_details/${encodeURIComponent(
                                                            item.title
                                                        )}`}>{item.name}</Link></h6>
                                                        <div className="rating mb-5">
                                                            <ul>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                            </ul>
                                                            {/* <span>(01 review)</span> */}
                                                        </div>
                                                        <div className="price mb-20">
                                                            <span>
                                                                ₹{item.sell_price} - <del>₹{item.price}</del>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            ))}
                    </div>
                    {/* <div className="col-xl-6 col-lg-6">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="section__head d-flex justify-content-between mb-30">
                            <div className="section__title section__title-2">
                                <h5 className="st-titile">Top Rate Products</h5>
                            </div>
                            <div className="button-wrap button-wrap-2">
                                <a href="product.html">See All <i className="fa fa-chevron-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl-12">
                        <div className="single-features-item b-radius-2 mb-20">
                            <div className="row  g-0 align-items-center">
                                <div className="col-4">
                                    <div className="features-thum">
                                        <div className="features-product-image w-img me-3">
                                            <a href="product-details.html"><img src={doubaleimg7} alt="logo" /></a>
                                        </div>
                                        <div className="product__offer">
                                            <span className="discount">-5%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="product__content product__content-d product__content-d-2">
                                        <h6><a href="product-details.html">Apple iPhone XR 64GB Red Dual-SIM</a></h6>
                                        <div className="rating mb-5">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            </ul>
                                            <span>(01 review)</span>
                                        </div>
                                        <div className="price d-price">
                                            <span>$165.00 <del>$110</del></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="single-features-item b-radius-2 mb-20">
                            <div className="row  g-0 align-items-center">
                                <div className="col-4">
                                    <div className="features-thum">
                                        <div className="features-product-image w-img me-3">
                                            <a href="product-details.html"><img src={doubaleimg8} alt="logo" /></a>
                                        </div>
                                        <div className="product__offer">
                                            <span className="discount">-25%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="product__content product__content-d product__content-d-2">
                                        <h6><a href="product-details.html">MW65 Wireless Headphones Z1000</a></h6>
                                        <div className="rating mb-5">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            </ul>
                                            <span>(01 review)</span>
                                        </div>
                                        <div className="price">
                                            <span>$130.00 <del>$110</del></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-12">
                        <div className="single-features-item b-radius-2 mb-20">
                            <div className="row  g-0 align-items-center">
                                <div className="col-4">
                                    <div className="features-thum">
                                        <div className="features-product-image w-img me-3">
                                            <a href="product-details.html"><img src={doubaleimg9} alt="logo" /></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="product__content product__content-d product__content-d-2">
                                        <h6><a href="product-details.html">All-new Fire HD 10 Tablet 10.1″ Full HD</a></h6>
                                        <div className="rating mb-5">
                                            <ul>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                                <li><a href="#"><i className="fa fa-star"></i></a></li>
                                            </ul>
                                            <span>(01 review)</span>
                                        </div>
                                        <div className="price">
                                            <span>$140.00 <del>$110</del></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                </div>
            </div>
        </div>
    )
}

export default HotOnSale