export function get(id) {
  var getJson = localStorage.getItem("persist:mk-store");
  if (getJson) {
    let data = JSON.parse(getJson);
    var jsonString = JSON.parse(data.cartData);
    var idArray = jsonString.map((item) => item.id);
    const updatedCartIdsArr = [...idArray, id]; 
    const allProductIdString = updatedCartIdsArr.join(',');
    return allProductIdString;
  } else {
    return null;
  }
}

export function remove(id) {
  var getJson = localStorage.getItem("persist:mk-store");
  if (getJson) {
    let data = JSON.parse(getJson);
    var jsonString = JSON.parse(data.cartData);
    var idArray = jsonString.map((item) => item.id);
    console.log(idArray)
    if (idArray.length == 1) {
      if (idArray[0] == id) {
        return 0
      }
    } else {    
      const updatedIdArray = idArray.filter((itemId) => itemId !== id);
      const allProductIdString = updatedIdArray.join(',');
      return allProductIdString;
    }
  } else {
    return null;
  }
}


