import { useQuery } from "react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { toastSuccess } from "../../utils/notifyCustom"
const fetchUser = async ({ api_key, MKuserId }) => {

  const formData = new FormData();
  formData.append("user_id", MKuserId);

  const myHeaders = new Headers();
  myHeaders.append("x-api-key", api_key);
  // myHeaders.append("Cookie", "ci_session=fm0fn8rmqsbfm6vr41l4rgsu2d3t35e4");

  const response = await fetch(
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_USER_DATA,
    {
      method: "POST",
      // headers: myHeaders,
      body: formData,
    }
  );

  return response.json();
};
const Account = () => {
  const [opne, setopne] = useState(false)
  const [userName, setuserName] = useState('')
  const [phone, setphone] = useState('')
  const [email, setemail] = useState('')
  const loadingBar = useRef(null);

  var api_key = JSON.parse(localStorage.getItem("MKapi_key"));
  var MKuserId = JSON.parse(localStorage.getItem("MKuserId"));
  const userQuery = useQuery(["user", { api_key, MKuserId }], () =>
    fetchUser({ api_key, MKuserId }), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  }
  );

  useEffect(() => {
    setuserName(userQuery.data && userQuery.data.name);
    setphone(userQuery.data && userQuery.data.phone);
    setemail(userQuery.data && userQuery.data.email);
  }, [userQuery.data && userQuery.data.name || userQuery.data && userQuery.data.phone || userQuery.data && userQuery.data.email]);

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (userQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [userQuery.isLoading]);

  if (userQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  // console.log("uuuuudddkkkkkkiiiii", userdataQuery.data);



  const handelFrom = () => {
    console.log("hhhhh", userName, phone, email, MKuserId);

    const profileformdata = new FormData();
    profileformdata.append("user_id", MKuserId);
    profileformdata.append("name", userName);
    profileformdata.append("email", email);
    profileformdata.append("phone", phone)

    axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_USER_PROFILE_SAVE, profileformdata)
      .then(response => {
        toastSuccess(response.data.text)
        userQuery.refetch()
      })
      .catch(error => console.error(error));
  }

  return (
    <main>
      <LoadingBar color="#f11946" ref={loadingBar} />
      {/* cart-area-start */}
      <section className="cart-area py-lg-5 py-md-5 py-3">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 col-md-12">
              <h5 className="mb-3">Your Account</h5>
              <div className="order_grid">
                <div className="order_box formTogle" style={{ display: opne === false ? "" : "none" }}>
                  <div className="">
                    <h6 className="mb-2">
                      <i className="far fa-user" /> {userQuery.data && userQuery.data.name}
                    </h6>
                    <h6 className="mb-2">
                      <i className="far fa-phone-alt" /> {userQuery.data && userQuery.data.phone}
                    </h6>
                    <h6 className="mb-0">
                      <i className="far fa-envelope" /> {userQuery.data && userQuery.data.email}
                    </h6>
                  </div>
                  <div>
                    <a
                      href="javascript:void(0)"
                      onclick="$('.formTogle').toggle()"
                      style={{ fontSize: 15 }}
                      className="mb-0 text-primary"
                      onClick={() => setopne(!opne)}
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <form
                  className="order_box form_inputs formTogle"
                  style={{ display: opne === true ? "" : "none" }}
                >
                  <div className="row gy-3">
                    <div className="col-lg-12">
                      <label>Your Name *</label>
                      <input
                        type="text"
                        className="form-control"

                        value={userName}
                        name=""
                        required=""
                        onChange={(e) => setuserName(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-5">
                      <label>Phone *</label>
                      <input
                        type="text"
                        className="form-control"
                        value={phone}
                        name=""
                        required=""
                        onChange={(e) => setphone(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-7">
                      <label>Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        value={email}
                        name=""
                        required=""
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn btn-md btn-dark"
                        onClick={() => setopne(!opne)}
                      >
                        Cancel
                      </button>
                      <button type="button" className="btn btn-md btn-primary" style={{ marginLeft: 3 }} onClick={handelFrom}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* cart-area-end */}
    </main>
  );
};

export default Account;
