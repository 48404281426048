import { useQuery } from "react-query";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import LoadingBar from "react-top-loading-bar";
import { toastSuccess } from "../../utils/notifyCustom"



const Address = () => {

  const fetchUserAddress = async () => {
    var MKuserId = JSON.parse(localStorage.getItem("MKuserId"))
    console.log(MKuserId, "yyyyyyyyy");
    const formData = new FormData();
    formData.append("user_id", MKuserId);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_USER_ADDRESS_DATA}`,
        formData
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching user address:", error);
      throw error;
    }
  };

  const [addType, setaddType] = useState('')
  const [EditId, setEditId] = useState('')
  const [pin, setpin] = useState('')
  const [city, setcity] = useState('')
  const [State, setState] = useState('')
  const [address, setaddress] = useState('')
  const [Landmark, setLandmark] = useState('')
  const [Notes, setNotes] = useState('')



  const loadingBar = useRef(null);
  var MKuserId = JSON.parse(localStorage.getItem("MKuserId"));
  const [opne, setopne] = useState(false)
  const [opneEdit, setopneEdit] = useState(false)


  const userAddressQuery = useQuery(["fetchUserAddres",], () => fetchUserAddress(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false
  }
  );

  const startLoading = () => {
    loadingBar.current.continuousStart();
  };

  const finishLoading = () => {
    loadingBar.current.complete();
  };

  useEffect(() => {
    if (userAddressQuery.isLoading) {
      startLoading();
    } else {
      finishLoading();
    }
  }, [userAddressQuery.isLoading]);

  if (userAddressQuery.isLoading) {
    return (
      <div>
        <LoadingBar color="#f11946" ref={loadingBar} />
        {/* <h2>Loading...</h2> */}
      </div>
    );
  }
  console.log(userAddressQuery.isError, "eeeeeeeeeeeee")

  const handelAddressFromEadit = (id) => {
    console.log(id, "fffffffffffffffffffffffffssssssssssssss")
    setEditId(id)
    setopneEdit(!opneEdit)
  }

  const handelAddressFrom = () => {
    console.log(EditId)
    const addressformdata = new FormData();
    addressformdata.append("user_id", MKuserId);
    addressformdata.append("id", EditId);
    addressformdata.append("type", addType);
    addressformdata.append("pin", pin);
    addressformdata.append("city", city);
    addressformdata.append("state", State);
    addressformdata.append("land_mark", Landmark);
    addressformdata.append("address", address);
    addressformdata.append("extra_note", Notes);


    axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_USER_ADDRESS_SAVE, addressformdata)
      .then(response => {
        setEditId('')
        userAddressQuery.refetch()
        toastSuccess(response.data.text);
      })
      .catch(error => console.error(error));

  }

  const handelAddressDeleteFrom = (id) => {
    const addressDeleteformdata = new FormData();
    addressDeleteformdata.append("user_id", MKuserId);
    addressDeleteformdata.append("id", id);
    axios.post(process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_USER_ADDRESS_DELETE, addressDeleteformdata)
      .then(response => {
        userAddressQuery.refetch()
        toastSuccess(response.data.message);
      })
      .catch(error => console.error(error));

  }



  console.log(opneEdit, "rrrrrrrrrrrr");

  return (
    <main>
      <LoadingBar color="#f11946" ref={loadingBar} />
      {/* cart-area-start */}
      <section className="cart-area py-lg-5 py-md-5 py-3">
        <div className="container">
          <div className="row">
            <div className="offset-lg-3 col-lg-6 col-md-12">
              <h5 className="mb-3">Your Address</h5>
              <div className="order_grid">
                <div style={{ display: opne === false ? "" : "none" }}>
                  {userAddressQuery.isError ? (
                    <div style={{ marginBottom: '12px', fontSize: '20px' }}>Please add your address</div>
                  ) : (
                    userAddressQuery && userAddressQuery.data ? (
                      <div className="order_box formTogle">
                        {userAddressQuery.data.map((item, index) => (
                          <div className="" key={index}>
                            <h6 className="mb-2">
                              <i className="fas fa-map-marker-alt" /> {item.type}
                            </h6>
                            <h6 className="mb-0">{item.state}, {item.city}, {item.address}, {item.pin}</h6>
                            <div>
                              <a
                                href="javascript:void(0)"
                                onClick={() => handelAddressFromEadit(item.id)}
                                style={{ fontSize: 15 }}
                                className="mb-0 text-primary"
                              >
                                Edit
                              </a>
                              <a
                                href="javascript:void(0)"
                                style={{ fontSize: 15 }}
                                className="mb-0 text-danger ms-1"
                                onClick={() => handelAddressDeleteFrom(item.id)}
                              >
                                Delete
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null
                  )}

                  <div className="order_box formTogle py-2">
                    <a
                      href="javascript:void(0)"
                      className="m-auto w-100 text-center"
                      onClick={() => setopne(!opne)}
                    >
                      + Add New Address
                    </a>
                  </div>
                </div>
                <form
                  className="order_box form_inputs formTogle"
                  style={{ display: opne === true ? "" : "none" }}
                >
                  <div className="row gy-3">
                    <div className="col-lg-6">
                      <label>Address Type</label>
                      <input value={addType} type="text" className="form-control" name="" onChange={(e) => setaddType(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                      <label>Pin code</label>
                      <input value={pin} type="text" className="form-control" name="" onChange={(e) => setpin(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                      <label>State *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>City *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label>Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label>Add Nearby shop/Mall/School/Landmark *</label>
                      <input value={Landmark} type="text" className="form-control" name="" onChange={(e) => setLandmark(e.target.value)} />
                    </div>
                    <div class="order-notes">
                      <div class="checkout-form-list">
                        <label>Order Notes</label>
                        <textarea
                          id="checkout-mess"
                          cols="30"
                          rows="10"
                          placeholder="Notes about your order, e.g. special notes for delivery."
                          value={Notes}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn btn-md btn-dark"
                        onClick={() => setopne(!opne)}
                      >
                        Cancel
                      </button>
                      <button onClick={handelAddressFrom} type="button" className="btn btn-md btn-primary" style={{ marginLeft: 3 }}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
                <form
                  className="order_box form_inputs formTogle"
                  style={{ display: opneEdit === true ? "" : "none" }}
                >
                  <div className="row gy-3">
                    <div className="col-lg-6">
                      <label>Address Type</label>
                      <input value={addType} type="text" className="form-control" name="" onChange={(e) => setaddType(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                      <label>Pin code</label>
                      <input value={pin} type="text" className="form-control" name="" onChange={(e) => setpin(e.target.value)} />
                    </div>
                    <div className="col-lg-6">
                      <label>State *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={State}
                        onChange={(e) => setState(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label>City *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label>Address *</label>
                      <input
                        type="text"
                        className="form-control"
                        name=""
                        required=""
                        value={address}
                        onChange={(e) => setaddress(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label>Add Nearby shop/Mall/School/Landmark *</label>
                      <input value={Landmark} type="text" className="form-control" name="" onChange={(e) => setLandmark(e.target.value)} />
                    </div>
                    <div class="order-notes">
                      <div class="checkout-form-list">
                        <label>Order Notes</label>
                        <textarea
                          id="checkout-mess"
                          cols="30"
                          rows="10"
                          placeholder="Notes about your order, e.g. special notes for delivery."
                          value={Notes}
                          onChange={(e) => setNotes(e.target.value)}
                        ></textarea>
                      </div>
                    </div>
                    <div className="col-12 text-end">
                      <button
                        type="button"
                        className="btn btn-md btn-dark"
                        onClick={() => setopneEdit(!opneEdit)}
                      >
                        Cancel
                      </button>
                      <button onClick={handelAddressFrom} type="button" className="btn btn-md btn-primary" style={{ marginLeft: 3 }}>
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* cart-area-end */}
    </main>

  )
}

export default Address